import React from 'react';
import { ADMIN_ROLES } from './AdminRoute';
import UserContext from 'core/context/user';
import { LoginRedirectWithRouter } from './LoginRedirectWithRouter';
import { NotFoundRoute } from './NotFoundRoute';
import { Route, RouteProps } from 'react-router-dom';

export const CONSULTANT_ROLES = ['expert', 'consultant'];

export type Props = {
  excludeAdmin?: boolean;
  redirect?: string;
} & RouteProps;

export const OnboardingConsultantRoute: React.FC<Props> = ({
  excludeAdmin = false,
  redirect,
  ...props
}) => {
  return (
    <UserContext.Consumer>
      {user => {
        const role = user?.role?.toLowerCase();
        const isAuthenticated = !!role;
        const hasAllowedRole =
          role &&
          ((ADMIN_ROLES.includes(role) && !excludeAdmin) ||
            CONSULTANT_ROLES.includes(role));

        if (!isAuthenticated) {
          return <LoginRedirectWithRouter redirect={redirect} />;
        }

        if (hasAllowedRole) {
          return <Route {...props} />;
        }

        return <NotFoundRoute {...props} />;
      }}
    </UserContext.Consumer>
  );
};
