import { oc } from 'ts-optchain';

export interface Flags {
  [key: string]: boolean;
}

export class FeatureFlagContainer {
  flags: Flags;

  constructor(flags = {}) {
    this.flags = { ...flags };
    Object.freeze(this.flags);
  }

  enabled(flag: string) {
    const val = oc(this).flags[flag]();
    return typeof val === 'boolean' ? val : false;
  }
}

export default FeatureFlagContainer;
