import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  AdminRoute,
  AuthenticatedRoute,
  ClientRoute,
  ConsultantRoute,
  OnboardingConsultantRoute,
  VettedAndVerifiedConsultantRoute,
} from 'core/route';
import { ErrorPage } from 'utils';

import * as PATHS from 'utils/constants/routes/paths';
import { PagesType } from 'pages';

export type Props = {
  pages: PagesType; // TODO: Output types when Flags utils converted to TS
  userToken?: string;
};

export const Router: React.FC<Props> = ({ pages }) => {
  return (
    <Switch>
      <ClientRoute path={PATHS.contacts.index} component={pages.Contact} />
      <AuthenticatedRoute
        path={PATHS.messages.index}
        component={pages.Messages}
      />
      <Route
        path={PATHS.privateTalent.acceptTerms}
        component={pages.InvitationTerms}
      />
      <AuthenticatedRoute
        path={PATHS.fms.organisation}
        component={pages.OrganisationDashboard}
      />
      <OnboardingConsultantRoute
        exact
        path={PATHS.profile.edit}
        component={pages.ExpertProfileBuilder}
        excludeAdmin
      />
      <Route path={PATHS.profile.show} component={pages.Profile} />
      <Route path={PATHS.reports.index} component={pages.Reports} />
      <OnboardingConsultantRoute
        path={PATHS.signUp.expertApplication}
        component={pages.ExpertApplication}
        excludeAdmin
      />
      <OnboardingConsultantRoute
        path={PATHS.expert.inviteWelcome}
        component={pages.InviteWelcome}
        excludeAdmin
      />
      <Route path={PATHS.signUp.expert} component={pages.ExpertSignUp} />
      <Route path={PATHS.signUp.client} component={pages.ClientSignUp} />
      <Route path={PATHS.signUp.bookMeeting} component={pages.BookMeeting} />
      <Route
        path={PATHS.signUp.index}
        render={({ location, ...props }) => {
          const { ExpertSignUp, ClientSignUp, SignUpWelcome } = pages;
          const { search } = location;

          const pageByIntent = (search: string) => {
            if (search.includes('?intent=hire')) return ClientSignUp;
            if (search.includes('?intent=work')) return ExpertSignUp;
            return SignUpWelcome;
          };

          const Page = pageByIntent(search);

          return <Page {...props} />;
        }}
      />
      <ClientRoute path={PATHS.explore.index} component={pages.Explore} />
      <ConsultantRoute path={PATHS.browse.index} component={pages.Browse} />
      <ConsultantRoute
        path={PATHS.expert.paymentSettings}
        component={pages.PaymentSettings}
      />
      <ConsultantRoute
        path={PATHS.expert.paymentDashboard}
        component={pages.PaymentDashboard}
      />
      <ConsultantRoute
        path={PATHS.expert.invoices}
        component={pages.Invoices}
      />
      <ConsultantRoute
        path={PATHS.expert.projectDetails}
        component={pages.ProjectDetails}
      />
      <ConsultantRoute
        path={PATHS.expert.archivedProjects}
        component={pages.Projects}
      />
      <ConsultantRoute
        path={PATHS.expert.activeProjects}
        component={pages.Projects}
      />
      <ConsultantRoute
        path={PATHS.expert.multiProjectTimesheets}
        component={pages.MultiProjectTimesheets}
      />
      <ConsultantRoute
        path={PATHS.expert.multiProjectTimesheetsReview}
        component={pages.MultiProjectTimesheetsReview}
      />
      <ClientRoute
        path={PATHS.client.projectTimesheets}
        component={pages.ClientProjectDetails}
      />
      <ClientRoute
        path={PATHS.client.projectTimesheetDetails}
        component={pages.TimesheetDetails}
      />
      <Route path={PATHS.projects.show} component={pages.Project} />
      <Route path={PATHS.projects.public} component={pages.Project} />
      <Route
        path={PATHS.privateTalent.acceptInvitation}
        component={pages.PrivateInvitation}
      />
      <AdminRoute
        path={PATHS.explore.advancedHelp}
        component={pages.ExploreAdvancedHelp}
      />
      <ClientRoute
        path={PATHS.explore.availability}
        component={pages.ExploreAvailability}
      />
      <ClientRoute
        path={PATHS.findExperts.index}
        component={pages.FindExpertsPage}
      />
      <ConsultantRoute
        path={PATHS.calendar.index}
        component={pages.Calendar}
        excludeAdmin
      />
      <Route
        path={PATHS.unsubscribe.confirm}
        component={pages.UnsubscribeConfirm}
      />
      <Route
        path={PATHS.unsubscribe.error}
        component={pages.UnsubscribeError}
      />
      <Route
        path={PATHS.feedback.projectRecommendation}
        component={pages.FeedbackProjectRecommendation}
      />
      <Route
        path={PATHS.availability.confirmation}
        component={pages.AvailabilityConfirmation}
      />
      <Route
        path={PATHS.availability.error}
        component={pages.AvailabilityError}
      />
      <Route
        path={PATHS.feedback.projectRecommendation}
        component={pages.FeedbackProjectRecommendation}
      />
      <VettedAndVerifiedConsultantRoute
        path={PATHS.partners.index}
        component={pages.Partners}
        excludeAdmin
      />
      <Route path={PATHS.login.totp} component={pages.LoginTotp} />
      <Route path={PATHS.login.index} component={pages.Login} />
      <Route path={PATHS.ssoLogin.index} component={pages.SsoLogin} />
      <Route
        path={PATHS.resetPassword.update}
        component={pages.ResetPassword}
      />
      <Route
        path={PATHS.resetPassword.index}
        component={pages.RequestResetPassword}
      />
      <Route path={PATHS.verify.expert} component={pages.ExpertVerification} />
      <ClientRoute
        path={PATHS.client.createProject}
        component={pages.CreateProject}
        excludedOrganisationRoles={['limited']}
      />
      <ClientRoute
        path={PATHS.client.editProject}
        component={pages.EditProject}
      />
      <ClientRoute
        path={PATHS.client.projectDetails}
        component={pages.ClientProjectDetails}
      />
      <Route
        path={PATHS.client.reviewTimesheetAnonymously}
        component={pages.ReviewTimesheetAnonymously}
      />
      <Route
        path={PATHS.client.approveContractOffer}
        component={pages.ApproveContractOffer}
      />
      <Route
        path={PATHS.client.claimAccount}
        component={pages.ClientClaimAccount}
      />
      <Route path={PATHS.admin.editProject} component={pages.EditProject} />
      <ClientRoute
        exact
        path={PATHS.client.index}
        component={pages.ClientProjectList}
      />
      <ClientRoute
        path={PATHS.client.activeProjects}
        component={pages.ClientProjectList}
      />
      <ClientRoute
        path={PATHS.client.closedProjects}
        component={pages.ClientProjectList}
      />

      <ClientRoute
        excludeAdmin
        path={[
          PATHS.client.projectExpertsToReviewSelected,
          PATHS.client.projectExpertsToReview,
        ]}
        component={pages.ExpertsToReview}
      />

      <ClientRoute
        path={PATHS.client.contractDetails}
        component={pages.ClientContractDetails}
      />

      <ClientRoute
        path={PATHS.client.projectContractsNew}
        component={pages.ContractList}
      />

      <ConsultantRoute
        path={PATHS.expertSettings.contactDetails}
        component={pages.ContactDetails}
      />

      <OnboardingConsultantRoute
        path={PATHS.expertSettings.accountDetails}
        component={pages.AccountDetails}
      />

      <Route
        path={PATHS.expert.confirmProjectOpportunityEmail}
        component={pages.ExpertConfirmProjectOpportunityEmail}
      />

      <Route
        path={PATHS.expert.optOutProjectEmail}
        component={pages.ExpertOptOutProjectEmail}
      />

      <ConsultantRoute
        path={PATHS.expertSettings.insurance}
        component={pages.Insurance}
      />

      <ConsultantRoute
        path={PATHS.expertSettings.paymentMethod}
        component={pages.PaymentMethod}
      />

      <ConsultantRoute path={PATHS.expertSettings.tax} component={pages.Tax} />

      <ConsultantRoute
        path={PATHS.expertSettings.yourBusiness}
        component={pages.YourBusiness}
      />
      <ConsultantRoute
        path={PATHS.expertSettings.preferences}
        component={pages.Preferences}
      />

      <ConsultantRoute
        path={PATHS.expertPayments.projectSelection}
        component={pages.ExpertPaymentsProjectSelection}
      />

      <ConsultantRoute
        path={PATHS.expertPayments.projectCreation}
        component={pages.ExpertPaymentsProjectCreation}
      />

      <Route
        path={PATHS.expertPayments.eligibility}
        component={pages.ExpertPaymentsEligibility}
      />

      <ConsultantRoute
        path={PATHS.expertPayments.invoiceUpload}
        component={pages.ExpertPaymentsInvoiceUpload}
      />

      <Route path={PATHS.expert.invite} component={pages.ExpertAcceptInvite} />

      <ConsultantRoute
        path={PATHS.expertPayments.idVerification}
        component={pages.ExpertPaymentsIDVerification}
      />

      <ConsultantRoute
        path={PATHS.notifications.index}
        component={pages.Notifications}
      />

      <ConsultantRoute
        path={PATHS.expert.dashboard}
        component={pages.ExpertDashboard}
      />

      <ConsultantRoute
        path={PATHS.expert.serviceRequest}
        component={pages.ExpertServiceRequest}
      />

      <Route path={PATHS.legal.show} component={pages.Legal} />

      <Route path={PATHS.mfa.setupTotp} component={pages.SetupTotp} />

      <Route path={PATHS.sharedSettings.mfa} component={pages.MfaSettings} />

      <Route
        path={PATHS.sharedSettings.changePassword}
        component={pages.ChangePassword}
      />

      <Route render={() => <ErrorPage status="404" />} />
    </Switch>
  );
};
