// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-module__container___rED4W {\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  z-index: 999;\n} ", "",{"version":3,"sources":["webpack://./src/segmentConsentManager/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,WAAW;EACX,YAAY;AACd","sourcesContent":[".container {\n  position: fixed;\n  bottom: 0;\n  width: 100%;\n  z-index: 999;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "index-module__container___rED4W"
};
module.exports = ___CSS_LOADER_EXPORT___;
