/**
 * @generated SignedSource<<bc30ff94aacd8b3251e4c2aa8845a74b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InvitingOrganisationQuery$variables = {
  name?: string | null;
};
export type InvitingOrganisationQuery$data = {
  readonly organisations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly lightWhitelabelImage: string | null;
        readonly name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type InvitingOrganisationQuery = {
  response: InvitingOrganisationQuery$data;
  variables: InvitingOrganisationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 1
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "OrganisationConnection",
    "kind": "LinkedField",
    "name": "organisations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganisationEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organisation",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lightWhitelabelImage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvitingOrganisationQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvitingOrganisationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9e30f00e141967f5dccdfbfa4257a907",
    "id": null,
    "metadata": {},
    "name": "InvitingOrganisationQuery",
    "operationKind": "query",
    "text": "query InvitingOrganisationQuery(\n  $name: String\n) {\n  organisations(first: 1, name: $name) {\n    edges {\n      node {\n        id\n        name\n        lightWhitelabelImage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbb83c7c7cd61c9e09314b34931c4151";

export default node;
