import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps<Record<string, string | undefined>>;

class ScrollToTop extends React.Component<Props, Record<string, unknown>> {
  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export function scrollTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
}

export default withRouter(ScrollToTop);
