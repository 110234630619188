import { ROUTES } from 'utils/constants';
import * as E360 from '@expert360';
import * as PathUtils from './pathUtils';
import { User as UserContextType } from '../../core/context/UserContextHandler';

const queryString = require('query-string');

type User = {
  role?: E360.UserRole | null | string;
  requiresTotpSetup?: boolean;
  consultant?: {
    lifecycle: Partial<E360.Lifecycle> | null;
  } | null;
  smsVerified?: boolean;
  emailVerified?: boolean;
};

const getHomePageForExpert = (user: User, isFlagged?: boolean) => {
  const isPrivateCloudMember =
    user.consultant?.lifecycle?.privateCloudTalent ?? false;
  const isMarketplaceMember =
    user.consultant?.lifecycle?.marketplaceMember ?? false;

  const isPendingPrivateCloudMember =
    !isMarketplaceMember && !isPrivateCloudMember;

  const isAcceptedAndVerified =
    (user.consultant?.lifecycle?.accepted ?? false) &&
    (user.consultant?.lifecycle?.emailVerified ?? false);

  const basicProfileCompleted =
    user.consultant?.lifecycle?.basicProfileCompleted ?? false;

  const smsVerified = user?.smsVerified;
  const emailVerified = user?.emailVerified;

  if (isAcceptedAndVerified || isPrivateCloudMember) {
    if (basicProfileCompleted) {
      return ROUTES.expert.dashboard;
    } else if (smsVerified || emailVerified || !isFlagged) {
      return ROUTES.profile.edit;
    }
  }

  if (isPendingPrivateCloudMember) {
    return ROUTES.privateTalent.acceptTerms({ slug: 'redirect' });
  }
  if (smsVerified || emailVerified || !isFlagged) {
    return ROUTES.signUp.expertApplication;
  }

  return ROUTES.signUp.expert;
};

const getRedirect = (
  user: User | UserContextType | null,
  isFlagged?: boolean
) => {
  const values = queryString.parse(window.location.search);

  /*
      XXX:
      The path specified in the `next` param is compared to the referrer to prevent a potential
      redirect loop in the edge case that a user is not allowed to view the page at `next`. If the
      user has been redirected to the login page from the path specified in `next`, ignore the
      `next` param and redirect the user to the default path for their role.
    */

  // User needs to setup their MFA stuff
  if (user?.requiresTotpSetup) {
    return ROUTES.mfa.setupTotp;
  }

  const role = user?.role ?? null;
  const roleFormatted = !!role ? role.toUpperCase() : null;
  if (values.next && !PathUtils.doPathsMatch(values.next, document.referrer)) {
    return values.next;
  }

  switch (roleFormatted) {
    case E360.UserRole.Client:
      return ROUTES.client.index;
    case E360.UserRole.Consultant:
      return getHomePageForExpert(user as User, isFlagged);
    case E360.UserRole.Admin:
    case E360.UserRole.Superadmin:
      return ROUTES.admin.index;
  }

  throw new Error(
    `Login: getRedirect expects a valid user role but received "${role}"`
  );
};

export default getRedirect;
