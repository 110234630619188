export const admin = {
  editProject: '/admin/project/edit/:slug',
  index: '/admin/v2',
};

export const browse = {
  index: '/browse',
};

export const calendar = {
  index: '/calendar',
};

export const client = {
  activeProjects: '/client/active-projects',
  approveContractOffer: '/client/offer/:slug/approve',
  reviewTimesheetAnonymously: '/client/invoices/:slug/review',
  createProject: '/create-project',
  closedProjects: '/client/closed-projects',
  editProject: '/client/project/edit/:slug',
  index: '/client',
  projectPending: '/client/project/pending/:slug', // lovecraft
  projectActive: '/client/project/active/:slug', // lovecraft
  projectOffers: '/client/project/offers/:slug', // lovecraft
  projectContracts: '/client/project/contracts/:code', // lovecraft
  projectDetails: '/client/project/view/:slug',
  projectTimesheets: '/projects/:slug/timesheets',
  projectContractsNew: '/projects/:slug/contracts',
  projectTimesheetDetails: '/timesheets/:invoiceCode',
  projectExpertsToReview: '/projects/:code/experts-to-review',
  projectExpertsToReviewSelected: '/projects/:code/experts-to-review/:slug',
  contractDetails: '/projects/:code/contracts/:slug',
  claimAccount: '/client/claim-account',
};

export const contacts = {
  index: '/contacts',
};

export const contracts = {
  index: '/contracts',
  show: '/contracts/:code', // lovecraft
  offer: '/contracts/offer/:code/:slug', // lovecraft
};

export const expert = {
  activeProjects: '/expert/project/:tab',
  archivedProjects: '/expert/project/:tab(archived)/:subTab',
  invoices: '/expert/invoices',
  projectDetails:
    '/expert/project/:option(view|invoices|offer|onboarding)/:slug',
  paymentDashboard: '/expert/payment/dashboard',
  paymentSettings: '/expert/payment/settings',
  pendingProjects: '/expert/project/pending',
  introduction: '/expert/introduction',
  contractDetails: '/expert/project/offer/:projecthash/:contractcode',
  dashboard: '/expert/dashboard',
  invite: '/expert/invite/:token',
  serviceRequest: '/expert/service-request',
  inviteWelcome: '/expert/invite/welcome',
  confirmProjectOpportunityEmail:
    '/expert/confirm-project-opportunity-email/:token',
  optOutProjectEmail: '/expert/opt-out-project-email',
  multiProjectTimesheets: '/expert/payment/multi-project-timesheets',
  multiProjectTimesheetsReview:
    '/expert/payment/multi-project-timesheets-review',
};

export const explore = {
  advancedHelp: '/explore-advanced-help',
  availability: '/explore-availability',
  index: '/explore',
};

export const findExperts = {
  index: '/find',
};

export const feedback = {
  projectRecommendation: '/feedback/project-recommendation',
  projectClientRating: '/feedback/client/project/:hash',
};

export const fms = {
  organisation: '/fms/organisation',
};

export const login = {
  index: '/login',
  totp: '/login/totp',
  exists: '/login/exists',
};

export const ssoLogin = {
  index: '/sso-login',
};

export const messages = {
  index: '/messages',
  thread: '/messages/thread/:slug/:code',
};

export const partners = {
  index: '/partners',
};

export const privateTalent = {
  acceptInvitation: '/private-talent/accept-invitation/:slug',
  acceptTerms: '/private-talent/accept-terms/:slug',
};

export const profile = {
  edit: '/profile/edit',
  show: '/profile/:slug',
};

export const projects = {
  show: '/project/:slug',
  public: '/public/:slug',
};

export const reports = {
  index: '/reports',
};

export const resetPassword = {
  index: '/reset-password',
  update: '/reset-password/reset',
  post: '/api/user/reset-password',
};

export const session = {
  index: '/session',
  sso: '/session/oidc',
  totp: '/session/totp',
};

export const signUp = {
  index: '/sign-up',
  client: '/sign-up/client',
  expert: '/sign-up/expert',
  expertApplication: '/sign-up/expert/application',
  bookMeeting: '/sign-up/book-meeting',
};

export const unsubscribe = {
  confirm: '/unsubscribe-confirm',
  error: '/unsubscribe-error',
};

export const availability = {
  confirmation: '/availability-confirmation',
  error: '/availability-error',
};

export const verify = {
  expert: '/verify/expert/:hash',
  sendExpertVerificationEmail: '/api/resend-verification/expert',
};

export const expertSettings = {
  accountDetails: '/settings/account-details',
  contactDetails: '/settings/contact-details',
  insurance: '/settings/insurance',
  paymentMethod: '/settings/payment-method',
  tax: '/settings/tax',
  yourBusiness: '/settings/your-business',
  preferences: '/settings/preferences',
};

export const sharedSettings = {
  changePassword: '/settings/change-password',
  mfa: '/settings/mfa',
};

export const legal = {
  show: '/legal/:slug',
  cookieNotice: '/legal/cookie-notice',
};

export const application = {
  headhunted: '/experts/headhunted',
};

export const pay360 = {
  index: '/pay360',
};

export const expertPayments = {
  projectSelection: '/expert/payments/project-selection',
  projectCreation: '/expert/payments/project-creation',
  eligibility: '/expert/payments/get-started',
  idVerification: '/expert/payments/identity-verification',
  invoiceUpload: '/expert/payments/invoice-upload/:projectCode',
};

export const notifications = {
  index: '/notifications',
};

export const mfa = {
  setupTotp: '/settings/setup-totp',
};
