const privateCloudCheck = (clientOrgs: any, consultantOrgs: any) => {
  const showPrivateCloud = clientOrgs.some((clientOrg: any) =>
    consultantOrgs.some((consultantOrg: any) => {
      if (consultantOrg?.name && clientOrg?.name) {
        return consultantOrg.name === clientOrg.name;
      }
      // The below conditions aren't useful right now, but may be in future
      // if we refactor how IDs are stored in ElasticSearch.
      // See https://github.com/expert360/expert360_com/pull/4991 for details on
      // opaque IDs.
      if (consultantOrg?.orgId) {
        return consultantOrg?.orgId === clientOrg?.id;
      }
      if (clientOrg?.orgId) {
        return consultantOrg?.id === clientOrg?.orgId;
      }
      if (consultantOrg?.id && clientOrg?.id) {
        return consultantOrg?.id === clientOrg?.id;
      }
      return false;
    })
  );
  return showPrivateCloud;
};

export { privateCloudCheck };
