import { withRouter } from 'react-router-dom';
import { compose } from 'utils';
import withRelayEnvironment from 'core/withRelayEnvironment';
import {
  UserContext,
  UserContextHandler,
  UnwrappedProps,
} from './UserContextHandler';

const UserContextUpdater: React.FC<UnwrappedProps> = compose(
  withRouter,
  withRelayEnvironment
)(UserContextHandler);

export { UserContextUpdater };
export default UserContext;
