import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { withTracking } from '@expert360/tracking';
import { compose } from 'utils';
import Logout from './Logout';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import * as UI from '@expert360/ui';
import NotificationIcon from '../NotificationIcon';
import { ROUTES } from 'utils/constants';

const isActive = (match, location) => {
  const { pathname } = location;
  if (
    pathname === '/expert/invoices/create' ||
    pathname === '/expert/payment/dashboard'
  ) {
    return true;
  }
  return false;
};

const Expert = ({
  userFirstName,
  userFullName,
  userProfileCode,
  tracking,
  data,
  isMenuOpen,
  toggleMenu,
  isVerified,
  isMarketplaceMember,
  referringCompany,
}) => {
  const isProfileComplete =
    data?.viewer?.user?.consultant?.lifecycle?.basicProfileCompleted ?? false;
  const profilePicture = data?.viewer?.user?.profilePicture?.source ?? null;

  const hasUnreadNotifications = data?.viewer?.hasUnreadNotifications ?? false;

  const displayBrowseProjects = data?.viewer?.displayBrowseProjects ?? false;

  const notificationRoute = ROUTES.notifications.index;
  const notificationFlagEnabled = useFeatureFlag('notifications');

  const showNotification = isProfileComplete && notificationFlagEnabled;
  const browseProjectsEnabled = displayBrowseProjects;

  return (
    <React.Fragment>
      <div className="l-header__nav-trigger l-flex-grid">
        {showNotification && (
          <div
            className="l-header__nav__item l-flex-grid__item"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <NavLink to={notificationRoute}>
              <NotificationIcon
                hasUnreadNotifications={hasUnreadNotifications}
              />
            </NavLink>
          </div>
        )}
        <div className="l-header__nav__item l-flex-grid__item">
          <button
            type="button"
            className="c-icon-only-button c-icon-only-button--menu c-burger-menux js-nav-trigger"
            onClick={() => toggleMenu(!isMenuOpen)}
          >
            <span />
            <span className="u-hide-visually">Open the site menu</span>
          </button>
        </div>
      </div>
      <div
        className={cx('l-header__nav', {
          'is-visible': isMenuOpen,
        })}
      >
        <nav className="l-header__nav__main" aria-hidden="false">
          <ul className="o-list-inline-from-1020">
            {isProfileComplete ? (
              <React.Fragment>
                <li className="l-header__nav__item">
                  <NavLink
                    to={ROUTES.expert.dashboard}
                    className="l-header__nav__item__link"
                    activeClassName="is-active"
                    onClick={() => toggleMenu(false)}
                  >
                    My dashboard
                  </NavLink>
                </li>
                {browseProjectsEnabled && (
                  <li className="l-header__nav__item">
                    <NavLink
                      to="/browse"
                      className="l-header__nav__item__link"
                      activeClassName="is-active"
                      onClick={() => toggleMenu(false)}
                    >
                      Browse Projects
                    </NavLink>
                  </li>
                )}
                <li className="l-header__nav__item">
                  <NavLink
                    to="/expert/project/pending"
                    exact={false}
                    className="l-header__nav__item__link"
                    activeClassName="is-active"
                    onClick={() => toggleMenu(false)}
                  >
                    My Projects
                  </NavLink>
                </li>
                <li className="l-header__nav__item">
                  <NavLink
                    to="/expert/payment/dashboard"
                    exact={false}
                    isActive={isActive}
                    className="l-header__nav__item__link"
                    activeClassName="is-active"
                    onClick={() => {
                      toggleMenu(false);
                      tracking.trackAction('Clicked', {
                        Title: 'Payments',
                        Location: 'Top Nav',
                      });
                    }}
                  >
                    Payments
                  </NavLink>
                </li>
                {isMarketplaceMember && (
                  <li className="l-header__nav__item">
                    <a
                      className="l-header__nav__item__link"
                      rel="noopener noreferrer"
                      href="/hub"
                    >
                      Expert Hub
                    </a>
                  </li>
                )}
              </React.Fragment>
            ) : isVerified ? (
              <li className="l-header__nav__item">
                <a href="/profile/edit" className="l-header__nav__item__link">
                  Profile Setup
                </a>
              </li>
            ) : null}
          </ul>
        </nav>
        <div className="l-header__nav__action">
          <ul className="o-list-inline-from-1020 l-flex-grid">
            {showNotification && !isMenuOpen && (
              <li
                className="l-header__nav__item l-header__nav__item--account l-flex-grid__item l-flex-grid__item--align-middle u-one-whole-up-to-1019"
                role="menu"
                aria-hidden="true"
              >
                <NavLink
                  to={notificationRoute}
                  className="l-header__nav__item__link o-button avatar"
                >
                  <div className="u-position-relative">
                    <div className="u-circle u-position-top-left">
                      <NotificationIcon
                        hasUnreadNotifications={hasUnreadNotifications}
                      />
                    </div>
                    <div className="u-s-ml-base">&nbsp;</div>
                  </div>
                </NavLink>
              </li>
            )}
            <li
              className="l-header__nav__item l-header__nav__item--account l-flex-grid__item l-flex-grid__item--align-middle u-one-whole-up-to-1019"
              role="menu"
              aria-hidden="true"
            >
              <button className="l-header__nav__item__link o-button avatar">
                <div className="u-position-relative">
                  <div className="u-circle u-position-top-left">
                    <UI.Avatar
                      name={userFullName}
                      size={UI.ESizes.Small}
                      imageUrl={profilePicture}
                    />
                  </div>
                  <div className="u-s-ml-base">
                    &nbsp;
                    <span className="u-hide-from-1019 u-s-pl u-position-absolute">
                      {userFirstName}
                    </span>
                  </div>
                </div>
              </button>
              <div
                className="l-header__nav__sub-nav l-header__nav__sub-nav--account l-header__nav__sub-nav--right"
                aria-hidden="true"
              >
                <ul>
                  {isProfileComplete && (
                    <React.Fragment>
                      <li className="l-header--sub-nav-text">My Account</li>
                      <li>
                        <NavLink
                          to={`/profile/${userProfileCode}`}
                          exact={false}
                          className="l-header__sub-nav-item"
                          activeClassName="is-active"
                          onClick={() => toggleMenu(false)}
                        >
                          View profile
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/profile/edit#profile"
                          exact={false}
                          className="l-header__sub-nav-item"
                          activeClassName="is-active"
                          onClick={() => toggleMenu(false)}
                        >
                          Edit profile
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/settings/contact-details"
                          exact={false}
                          className="l-header__sub-nav-item"
                          activeClassName="is-active"
                          onClick={() => toggleMenu(false)}
                        >
                          Settings
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/expert/service-request"
                          exact={false}
                          className="l-header__sub-nav-item"
                          activeClassName="is-active"
                          onClick={() => toggleMenu(false)}
                        >
                          Service request
                        </NavLink>
                      </li>
                    </React.Fragment>
                  )}
                  {!isProfileComplete && (
                    <li>
                      <NavLink
                        to="/settings/account-details"
                        exact={false}
                        className="l-header__sub-nav-item"
                        activeClassName="is-active"
                        onClick={() => toggleMenu(false)}
                      >
                        Settings
                      </NavLink>
                    </li>
                  )}
                  {isMarketplaceMember ? (
                    <li>
                      <a
                        href="https://help.expert360.com/en/collections/1419837-expert-guides"
                        className="l-header__sub-nav-item"
                        tabIndex="0"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Resources
                      </a>
                    </li>
                  ) : null}

                  {isMarketplaceMember &&
                  isVerified &&
                  !['LPSNZ', 'LPS Mesh'].includes(referringCompany) ? (
                    <li>
                      <NavLink
                        to="/partners"
                        exact={false}
                        className="l-header__sub-nav-item"
                        activeClassName="is-active"
                        onClick={() => toggleMenu(false)}
                      >
                        Community Partnerships
                      </NavLink>
                    </li>
                  ) : null}
                  <li>
                    <Logout />
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default compose(withTracking)(Expert);
