// Gets the pathname (in a way compatible with IE) given a URL string
const getPath = (url: string): string => {
  const link = document.createElement('a');
  link.href = url;
  return link.pathname;
};

const trimSlash = (str: string): string => {
  const start = str.startsWith('/') ? 1 : 0;
  const end = str.endsWith('/') ? str.length - 1 : str.length;
  return str.substring(start, end);
};

const doPathsMatch = (urlOne: string, urlTwo: string): boolean => {
  const pathOne = trimSlash(getPath(urlOne));
  const pathTwo = trimSlash(getPath(urlTwo));
  return pathOne === pathTwo;
};

export { doPathsMatch };
