import React from 'react';
import { withConfig } from 'utils';
import * as PATHS from 'utils/constants/routes/paths';
const { ConsentManager } = require('@segment/consent-manager');
const { isInEUTimezone } = require('@segment/in-eu');
import styles from './index.module.css';

type Config = {
  SEGMENT_API_KEY: string;
};

type Props = {
  config: Config;
};

const bannerContent = (
  <span>
    We use cookies (and other similar technologies) to collect data to improve
    your experience on our site. By using our website, you’re agreeing to the
    collection of data as described in our{' '}
    <a
      href={PATHS.legal.cookieNotice}
      target="_blank"
      rel="noopener noreferrer"
    >
      Cookie Notice
    </a>
    .
  </span>
);

const preferencesDialogContent = (
  <div>
    <p>
      Expert360 uses data collected by cookies and JavaScript libraries to
      improve your browsing experience, analyze site traffic, deliver
      personalized advertisements, and increase the overall performance of our
      site.
    </p>
    <p>
      By using our website, you’re agreeing to our{' '}
      <a
        href={PATHS.legal.cookieNotice}
        target="_blank"
        rel="noopener noreferrer"
      >
        Cookie Notice
      </a>
      .
    </p>
    <p>
      The table below outlines how we use this data by category. To opt out of a
      category of data collection, select “No” and save your preferences.
    </p>
  </div>
);

const cancelDialogContent = (
  <div>
    Your preferences have not been saved. By continuing to use our website,
    you’re agreeing to our{' '}
    <a
      href={PATHS.legal.cookieNotice}
      target="_blank"
      rel="noopener noreferrer"
    >
      Cookie Notice
    </a>
    .
  </div>
);

// consent to all if user closes banner
const closeBehavior = (categories: any) => ({
  ...categories,
  advertising: true,
  functional: true,
  marketingAndAnalytics: true,
});

// if in EU they are opted out by default
const initialPreferences = isInEUTimezone()
  ? {}
  : {
      advertising: true,
      functional: true,
      marketingAndAnalytics: true,
    };

const SegmentConsentManager: React.FC<Props> = ({ config }) => {
  return (
    <div className={styles.container}>
      <ConsentManager
        writeKey={config.SEGMENT_API_KEY}
        shouldRequireConsent={isInEUTimezone}
        bannerContent={bannerContent}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogContent={cancelDialogContent}
        closeBehavior={closeBehavior}
        initialPreferences={initialPreferences}
      ></ConsentManager>
    </div>
  );
};

export default withConfig(SegmentConsentManager);
