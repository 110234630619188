import React from 'react';
import * as UI from '@expert360/ui';

export interface LoaderProps {
  error?: string;
  pastDelay?: boolean;
}
const Loader: React.FC<LoaderProps> = ({ error, pastDelay = true }) => {
  if (pastDelay) {
    return (
      <div className="c-loading">
        <div className="l-strap">
          <UI.Loader size={UI.ESizes.Medium} />
        </div>
      </div>
    );
  } else {
    if (error) {
      console.error(error);
    }
    return null;
  }
};

export default Loader;
