export interface EnvVariables {
  [key: string]: string | null;
}

export class EnvVariableContainer {
  envVariables: EnvVariables;

  constructor(variables = {}) {
    this.envVariables = { ...variables };
    Object.freeze(this.envVariables);
  }

  getValue(key: string) {
    return this.envVariables?.[key];
  }
}

export default EnvVariableContainer;
