import React from 'react';
import { RouteProps } from 'react-router-dom';
import { RoleRoute } from './RoleRoute';

const ADMIN_ROLE = 'admin';
const SUPERADMIN_ROLE = 'superadmin';

export const ADMIN_ROLES = [ADMIN_ROLE, SUPERADMIN_ROLE];

export const AdminRoute: React.FC<RouteProps> = props => (
  <RoleRoute roles={ADMIN_ROLES} {...props} />
);
