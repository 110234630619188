import React from 'react';
import * as UI from '@expert360/ui';
import { BREAKPOINTS } from 'utils/constants';

import styles from '../../NotificationBanner.module.css';

const message = '';

const NotificationBanner = () => {
  return (
    <UI.Container>
      <UI.Row className={styles.notificationRow}>
        <UI.Col>
          <UI.Text
            size={
              BREAKPOINTS.isSmallDown() ? UI.ESizes.Small : UI.ESizes.Medium
            }
          >
            {message}
          </UI.Text>{' '}
        </UI.Col>
      </UI.Row>
    </UI.Container>
  );
};

export default NotificationBanner;
