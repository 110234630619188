// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-module__container___C_SvV {\n  position: fixed;\n  width: 100%;\n  display: block;\n  background: #EBEBEB;\n  opacity: 0.7;\n  z-index: 5;\n  top: 0;\n  bottom: 0;\n}\n\n.index-module__inner-container___as5jF {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  margin-top: 104px;\n}\n", "",{"version":3,"sources":["webpack://./src/shared/Loading/index.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,cAAc;EACd,mBAAiC;EACjC,YAAY;EACZ,UAAU;EACV,MAAM;EACN,SAAS;AACX;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,iBAAqB;AACvB","sourcesContent":[".container {\n  position: fixed;\n  width: 100%;\n  display: block;\n  background: color(neutralDark080);\n  opacity: 0.7;\n  z-index: 5;\n  top: 0;\n  bottom: 0;\n}\n\n.inner-container {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  margin-top: units(26);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "index-module__container___C_SvV",
	"innerContainer": "index-module__inner-container___as5jF"
};
module.exports = ___CSS_LOADER_EXPORT___;
