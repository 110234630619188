import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { withTracking } from '@expert360/tracking';
import Logout from './Logout';
import * as UI from '@expert360/ui';

// TODO: Add active className on nav__items using React-router
const ClientFallback = ({
  userFirstName,
  userFullName,
  userProfileCode,
  userProfileImg,
  userOrganisationRole,
  isVetted,
  tracking,
  isMenuOpen,
  toggleMenu,
  isExploreEnabled,
}) => {
  const canCreateProject = isVetted && userOrganisationRole != 'limited';
  return (
    <React.Fragment>
      <div className="l-header__nav-trigger l-flex-grid">
        <div className="l-header__nav__item l-flex-grid__item">
          <button
            type="button"
            className="c-icon-only-button c-icon-only-button--menu c-burger-menux js-nav-trigger"
            aria-haspopup="true"
            onClick={() => toggleMenu(!isMenuOpen)}
          >
            <span />
            <span className="u-hide-visually">Open the site menu</span>
          </button>
        </div>
      </div>
      <div
        className={cx('l-header__nav js-nav-target', {
          'is-visible': isMenuOpen,
        })}
      >
        {isVetted ? (
          <nav className="l-header__nav__main" aria-hidden="false">
            <ul className="o-list-inline-from-1020 l-header__nav__list_client">
              <li className="l-header__nav__item">
                <a href="/client" className="l-header__nav__item__link">
                  My Projects
                </a>
              </li>
              <li className="l-header__nav__item">
                <NavLink
                  to="/contacts/all"
                  className="l-header__nav__item__link"
                  activeClassName="is-active"
                  onClick={() => toggleMenu(false)}
                >
                  Contacts
                </NavLink>
              </li>
              {isExploreEnabled ? (
                <li className="l-header__nav__item">
                  <NavLink
                    to="/find"
                    className="l-header__nav__item__link"
                    activeClassName="is-active"
                    onClick={() => toggleMenu(false)}
                  >
                    Find Experts
                  </NavLink>
                </li>
              ) : null}
            </ul>
          </nav>
        ) : null}
        <div className="l-header__nav__action">
          <ul className="o-list-inline-from-1020 l-header__nav__list_client l-flex-grid">
            {canCreateProject ? (
              <li className="l-header__nav__item l-flex-grid__item l-flex-grid__item--align-middle u-one-whole-up-to-1019 u-s-pr-small">
                <a
                  href="/create-project"
                  className="js-nav-create l-header__nav__item__link l-header__nav__item--button--primary u-s-pt-tiny"
                  onClick={() => {
                    tracking.trackAction('Clicked', {
                      Title: 'Create',
                      Location: 'Main Navigation',
                    });
                  }}
                >
                  Create Project
                </a>
              </li>
            ) : null}
            <li
              className="l-header__nav__item l-header__nav__item--account l-flex-grid__item l-flex-grid__item--align-middle u-one-whole-up-to-1019"
              role="menu"
              aria-hidden="true"
            >
              <button className="l-header__nav__item__link is-client o-button avatar">
                <div className="u-position-relative">
                  <div className="u-circle u-position-top-left">
                    <UI.Avatar
                      name={userFullName}
                      size={UI.ESizes.Small}
                      imageUrl={userProfileImg}
                    />
                  </div>
                  <div className="u-s-ml-base">
                    &nbsp;
                    <span className="u-hide-from-1019 u-s-pl u-position-absolute">
                      {userFirstName}
                    </span>
                  </div>
                </div>
              </button>
              <div
                className="l-header__nav__sub-nav is-client l-header__nav__sub-nav--account l-header__nav__sub-nav--right"
                aria-hidden="true"
              >
                <ul>
                  <div className="l-header__sub-nav-item--divider is-top"></div>
                  <li className="l-header--sub-nav-text">{userFullName}</li>
                  <li>
                    <Logout isClient />
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTracking(ClientFallback);
