import React from 'react';
import {
  Admin,
  ClientRenderer,
  ExpertRenderer,
  Guest,
  Logo,
  NotificationBanner,
} from 'layout/components/Header';
import InvitingOrganisation from './components/Header/InvitingOrganisation';
import ReferringCompany from './components/Header/ReferringCompany';
import './Header.css';

const Header = ({
  user,
  whitelabelImageUrl = '',
  hideCta,
  referringCompanyImageUrl = '',
}) => {
  const role = user?.role ?? 'GUEST';
  const userFirstName = user?.firstName;
  const userLastName = user?.lastName;
  const userFullName = `${userFirstName} ${userLastName}`;
  const isProfileComplete = user?.consultant?.lifecycle?.basicProfileCompleted;
  const userProfileCode = user?.profileCode;
  const userProfileImg = user?.profilePicture?.source ?? null;
  const userVerificationLevel = user?.verificationLevel;
  const whitelabelImage = whitelabelImageUrl
    ? whitelabelImageUrl
    : user?.lightWhitelabelImage;
  const isMarketplaceMember = user?.consultant?.lifecycle?.marketplaceMember;
  const isBainPrivateTalent =
    !isMarketplaceMember && user?.consultant?.memberOf?.id == 76137;
  const userOrganisationRole = user?.organisationRole;
  const isExploreEnabled = user?.isExploreEnabled;
  const referringCompanyImage = referringCompanyImageUrl
    ? referringCompanyImageUrl
    : user?.companyLogo;
  const referringCompany = user?.referringCompany;

  const templates = {
    guest: <Guest hideCta={hideCta} />,
    admin: <Admin userFirstName={userFirstName} userImage={userProfileImg} />,
    superadmin: (
      <Admin userFirstName={userFirstName} userImage={userProfileImg} />
    ),
    client: (
      <ClientRenderer
        userFirstName={userFirstName}
        userFullName={userFullName}
        userProfileCode={userProfileCode}
        userProfileImg={userProfileImg}
        userVerificationLevel={userVerificationLevel}
        userOrganisationRole={userOrganisationRole}
        isExploreEnabled={isExploreEnabled}
      />
    ),
    consultant: (
      <ExpertRenderer
        userFirstName={userFirstName}
        userFullName={userFullName}
        isProfileComplete={isProfileComplete}
        userProfileCode={userProfileCode}
        userProfileImg={userProfileImg}
        userVerificationLevel={userVerificationLevel}
        isMarketplaceMember={isMarketplaceMember}
        referringCompany={referringCompany}
      />
    ),
  };

  const showNotification = false; // notifications not in use right now

  return (
    <div className={`react-nav ${showNotification ? 'with-notification' : ''}`}>
      <header className="l-header" role="banner">
        <div
          className={`l-header__main ${
            showNotification ? 'with-notification' : ''
          }`}
        >
          {showNotification ? (
            <div className="notification-banner">
              <div className="l-container-from-1020">
                <NotificationBanner />
              </div>
            </div>
          ) : null}
          <div className="l-header__inner l-container-from-1020">
            <a href="/" rel="home" className="l-header__logo">
              <div className="l-header__logo__inner">
                <Logo
                  whitelabelImage={whitelabelImage}
                  referringCompanyImageUrl={referringCompanyImage}
                />
              </div>
            </a>
            {templates[role.toLowerCase()]}
          </div>
        </div>
        <noscript>
          <div
            className="c-feedback c-feedback--error is-visible
            u-text-align-center"
          >
            For the best experience on Expert360 we recommend you enable
            JavaScript. You can find instructions on how to enable JavaScript in
            your web browser
            <a
              href="http://www.enable-javascript.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>
            .
          </div>
        </noscript>
      </header>
    </div>
  );
};

const HeaderRenderer = ({ user }) => {
  const pathname = window.location.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  const invitingOrganisation = urlParams.get('invitingOrganisation');
  const isLogin = ['/login', '/sso-login'].indexOf(pathname) > -1;
  const referringCompany = urlParams.get('referringCompany');
  const isAcceptingPrivateCloudInvitation = pathname.includes(
    'accept-invitation'
  );
  const isAcceptingPrivateCloudTerms = pathname.includes('accept-terms');

  /* 
    show the white label image of the organisation if a
    marketplace user that was invited to join a private cloud
    is trying to login or create new account
  */
  if (
    (isLogin && invitingOrganisation) ||
    ((isAcceptingPrivateCloudInvitation || isAcceptingPrivateCloudTerms) &&
      invitingOrganisation)
  ) {
    return <InvitingOrganisation user={user} name={invitingOrganisation} />;
  } else if (referringCompany && !user?.role) {
    return <ReferringCompany user={user} name={referringCompany} />;
  } else {
    return <Header user={user}></Header>;
  }
};

export { Header };
export default HeaderRenderer;
