import {
  Environment,
  Network,
  RecordSource,
  Store,
  Observable,
} from 'relay-runtime';

const fetchQuery = (operation: any, variables: any, csrfToken: string) => {
  return Observable.create(sink => {
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': csrfToken,
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.errors) {
          sink.error(data.errors);
          return;
        }
        sink.next(data);
        sink.complete();
      });
  });
};

const createEnvironment = (csrfToken: string) => {
  return new Environment({
    network: Network.create((operation, variables) =>
      fetchQuery(operation, variables, csrfToken)
    ),
    store: new Store(new RecordSource()),
  });
};

export default createEnvironment;
