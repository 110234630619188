import React from 'react';
import { Tracking, TrackingProvider } from '@expert360/tracking';
import FeedbackContext from 'core/context/feedback';
import RelayEnvironmentContext from 'core/context/relayEnvironment';
import {
  FeatureFlagContainer,
  ConfigProvider,
  FeatureFlagProvider,
  EnvVariableContainer,
  EnvVariableProvider,
} from 'utils';
import { EnvVariables } from 'utils/EnvVariableContainer/EnvVariableContainer';

const Providers = ({
  children,
  config,
  user,
  flags,
  renderFeedback,
  envVariables,
  relayEnvironment,
}) => {
  const trackingInstance = new Tracking({
    segment: {
      apiKey: config.SEGMENT_API_KEY,
      options: {
        integrations: {
          FullStory: /^(?!\/admin)/i.test(window.location.pathname),
        },
      },
    },
    isImpersonating: user?.isImpersonating ?? false,
    user: user || {},
  });

  const featureFlagContainer = new FeatureFlagContainer(flags);
  const envVariableContainer = new EnvVariableContainer(envVariables);

  return (
    <TrackingProvider instance={trackingInstance}>
      <FeatureFlagProvider.Provider value={featureFlagContainer}>
        <EnvVariableProvider.Provider value={envVariableContainer}>
          <ConfigProvider config={config}>
            <FeedbackContext.Provider value={renderFeedback}>
              <RelayEnvironmentContext.Provider
                value={{
                  relayEnvironment,
                }}
              >
                {children}
              </RelayEnvironmentContext.Provider>
            </FeedbackContext.Provider>
          </ConfigProvider>
        </EnvVariableProvider.Provider>
      </FeatureFlagProvider.Provider>
    </TrackingProvider>
  );
};

export default Providers;
