import React, { useState, useContext, useEffect } from 'react';
import { RouteProps } from 'react-router-dom';
import UserContext from '../../context/user';
import Loading from '../../../shared/Loading';
import { LoginRedirectWithRouter } from '../LoginRedirectWithRouter';
import { NotFoundRoute } from '../NotFoundRoute';
import { AllowedRoleRoute } from './AllowedRoleRoute';
import { ORGANISATION_ROLES } from '../ClientRoute';
import * as E360 from '@expert360';
import * as PATHS from 'utils/constants/routes/paths';

export type Props = {
  // TODO: enum
  roles: string[];
  organisationRoles?: string[];
  redirect?: string;
  path?: string | string[] | readonly string[];
} & RouteProps;

type State = {
  role: E360.UserRole | null;
  organisationRole: E360.OrganisationRole | null;
  loading: boolean;
  isUserAuthenticated: boolean;
};

const INITIAL_STATE: State = {
  role: null,
  organisationRole: null,
  loading: true,
  isUserAuthenticated: false,
};

const EXPLORE_PATHS = [PATHS.explore.index, PATHS.findExperts.index];

const isIncludedInAllowedRoles = (roles: string[], userRole: string | null) =>
  !!userRole && roles.includes(userRole.toLowerCase());

// Only check if user org role if the user is a client
const isIncludedInAllowedOrganisationRoles = (
  role: string | null,
  organisationRoles: string[],
  organisationRole: string | null
) => {
  if (role == E360.UserRole.Client) {
    return isIncludedInAllowedRoles(organisationRoles, organisationRole);
  } else {
    return true;
  }
};

const isExploreEnabledForClient = (user: any) => {
  return [E360.UserRole.Admin, E360.UserRole.Superadmin].includes(user?.role)
    ? true
    : [E360.UserRole.Client].includes(user?.role)
    ? !!user?.client?.memberOf[0]?.hasExplore
    : false;
};

const isUserLocked = (user: any) => user?.locked;

export const RoleRoute: React.FC<Props> = ({
  roles,
  organisationRoles = ORGANISATION_ROLES,
  redirect,
  ...props
}) => {
  const [state, setState] = useState<State>(INITIAL_STATE);
  const user: any = useContext(UserContext);

  useEffect(() => {
    const role = user?.role ?? null;
    const organisationRole = user?.organisationRole ?? null;

    if (role === null) {
      setState({
        role: null,
        organisationRole: null,
        isUserAuthenticated: false,
        loading: false,
      });
    } else {
      setState({
        role,
        organisationRole,
        isUserAuthenticated: true,
        loading: false,
      });
    }
  }, [user?.role]);

  if (state.loading) {
    return <Loading />;
  }

  if (!state.isUserAuthenticated) {
    return <LoginRedirectWithRouter redirect={redirect} />;
  }

  if (
    !user ||
    isUserLocked(user) ||
    !isIncludedInAllowedRoles(roles, state.role) ||
    !isIncludedInAllowedOrganisationRoles(
      state.role,
      organisationRoles,
      state.organisationRole
    ) ||
    (EXPLORE_PATHS.indexOf(props.path as string) > -1 &&
      !isExploreEnabledForClient(user))
  ) {
    return <NotFoundRoute {...props} />;
  }

  return <AllowedRoleRoute user={user} {...props} />;
};
