import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import Logout from './Logout';
import * as UI from '@expert360/ui';
import { useFeatureFlag } from 'hooks/useFeatureFlags';

const Admin = ({ userFirstName, userImage }) => {
  const [isMenuOpen, toggleMenu] = useState(false);

  return (
    <React.Fragment>
      <div className="l-header__nav-trigger">
        <button
          type="button"
          className="c-icon-only-button c-icon-only-button--menu c-burger-menux js-nav-trigger"
          aria-haspopup="true"
          onClick={() => toggleMenu(!isMenuOpen)}
        >
          <span />
          <span className="u-hide-visually">Open the site menu</span>
        </button>
      </div>
      <div
        className={cx('l-header__nav js-nav-target', {
          'is-visible': isMenuOpen,
        })}
      >
        <nav className="l-header__nav__main" aria-hidden="false">
          <ul className="o-list-inline-from-1020">
            <li className="l-header__nav__item">
              <a href="/admin/v2" className="l-header__nav__item__link">
                Backoffice
              </a>
            </li>
            <li className="l-header__nav__item">
              <NavLink
                to="/find"
                className="l-header__nav__item__link"
                activeClassName="is-active"
                onClick={() => toggleMenu(false)}
              >
                Find Experts
              </NavLink>
            </li>
            <li className="l-header__nav__item">
              <NavLink
                to="/browse"
                className="l-header__nav__item__link"
                activeClassName="is-active"
                onClick={() => toggleMenu(false)}
              >
                Browse Projects
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className="l-header__nav__action">
          <ul className="o-list-inline-from-1020">
            <li
              className="l-header__nav__item l-header__nav__item--account"
              role="menu"
              aria-hidden="true"
            >
              <button className="l-header__nav__item__link o-button avatar">
                <div className="u-position-relative">
                  <div className="u-circle u-position-top-left">
                    <UI.Avatar
                      name={userFirstName}
                      size={UI.ESizes.Small}
                      imageUrl={userImage}
                    />
                  </div>
                  <div className="u-s-ml-base">
                    &nbsp;
                    <span className="u-hide-from-1019 u-s-pl">
                      {userFirstName}
                    </span>
                  </div>
                </div>
              </button>
              <div
                className="l-header__nav__sub-nav l-header__nav__sub-nav--account l-header__nav__sub-nav--right"
                aria-hidden="true"
              >
                <ul>
                  <li>
                    <a
                      href="/admin"
                      className="l-header__sub-nav-item"
                      tabIndex="0"
                    >
                      Admin
                    </a>
                  </li>
                  <li>
                    <NavLink
                      to="/explore"
                      className="l-header__sub-nav-item"
                      activeClassName="is-active"
                      onClick={() => toggleMenu(false)}
                    >
                      Explore
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/settings/change-password"
                      className="l-header__sub-nav-item"
                      activeClassName="is-active"
                      onClick={() => toggleMenu(false)}
                    >
                      Settings
                    </NavLink>
                  </li>
                  <li>
                    <Logout />
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Admin;
