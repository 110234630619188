/**
 * @generated SignedSource<<6acefb8f3152cdab2dc43fc79f126752>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrganisationRole = "ADMIN" | "LIMITED" | "MEMBER" | "%future added value";
export type UserRole = "ADMIN" | "CLIENT" | "CONSULTANT" | "SUPERADMIN" | "%future added value";
export type VerificationLevel = "FUTURED" | "UNVETTED" | "VETTED" | "%future added value";
export type UserContextHandlerQuery$variables = {};
export type UserContextHandlerQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly client: {
        readonly memberOf: ReadonlyArray<{
          readonly hasExplore: boolean | null;
          readonly name: string;
          readonly ssoConfig: {
            readonly requireSso: boolean | null;
          } | null;
        }>;
      } | null;
      readonly consultant: {
        readonly lifecycle: {
          readonly accepted: boolean | null;
          readonly basicProfileCompleted: boolean | null;
          readonly claimed: boolean | null;
          readonly emailVerified: boolean | null;
          readonly marketplaceMember: boolean | null;
          readonly privateCloudTalent: boolean | null;
        } | null;
      } | null;
      readonly email: string | null;
      readonly emailVerified: boolean | null;
      readonly id: string;
      readonly locked: boolean | null;
      readonly organisationRole: OrganisationRole | null;
      readonly requiresTotpSetup: boolean | null;
      readonly role: UserRole | null;
      readonly smsVerified: boolean | null;
      readonly source: string | null;
      readonly verificationLevel: VerificationLevel | null;
    } | null;
  } | null;
};
export type UserContextHandlerQuery = {
  response: UserContextHandlerQuery$data;
  variables: UserContextHandlerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organisationRole",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verificationLevel",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiresTotpSetup",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locked",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "smsVerified",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailVerified",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accepted",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "basicProfileCompleted",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "marketplaceMember",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "privateCloudTalent",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "claimed",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasExplore",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requireSso",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserContextHandlerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Consultant",
                "kind": "LinkedField",
                "name": "consultant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Lifecycle",
                    "kind": "LinkedField",
                    "name": "lifecycle",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v8/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organisation",
                    "kind": "LinkedField",
                    "name": "memberOf",
                    "plural": true,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SsoConfig",
                        "kind": "LinkedField",
                        "name": "ssoConfig",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserContextHandlerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Consultant",
                "kind": "LinkedField",
                "name": "consultant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Lifecycle",
                    "kind": "LinkedField",
                    "name": "lifecycle",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v8/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organisation",
                    "kind": "LinkedField",
                    "name": "memberOf",
                    "plural": true,
                    "selections": [
                      (v15/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SsoConfig",
                        "kind": "LinkedField",
                        "name": "ssoConfig",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d17d07b94dd7d4a6b709120a67f55eee",
    "id": null,
    "metadata": {},
    "name": "UserContextHandlerQuery",
    "operationKind": "query",
    "text": "query UserContextHandlerQuery {\n  viewer {\n    user {\n      id\n      role\n      organisationRole\n      verificationLevel\n      requiresTotpSetup\n      email\n      locked\n      smsVerified\n      emailVerified\n      source\n      consultant {\n        lifecycle {\n          accepted\n          emailVerified\n          basicProfileCompleted\n          marketplaceMember\n          privateCloudTalent\n          claimed\n          id\n        }\n        id\n      }\n      client {\n        memberOf {\n          name\n          hasExplore\n          ssoConfig {\n            requireSso\n            id\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "678d89ef03482cc852506c2703a0ec22";

export default node;
