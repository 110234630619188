import React, { useState } from 'react';
import { graphql } from 'react-relay';
import { QueryRendererContainer } from 'utils';
import withRelayEnvironment from 'core/withRelayEnvironment';
import Client from './Client';
import ClientFallBack from './ClientFallback';

const ClientRendererQuery = graphql`
  query ClientRendererQuery {
    viewer {
      user {
        isOrgAdmin
        organisation {
          name
          hasFms
        }
        profilePicture(size: LARGE) {
          source
        }
      }
    }
  }
`;

const ClientRenderer = ({
  csrfToken,
  userFirstName,
  userFullName,
  userProfileCode,
  userProfileImg,
  userVerificationLevel,
  userOrganisationRole,
  relayEnvironment,
  tracking,
  isExploreEnabled,
}) => {
  const [isMenuOpen, toggleMenu] = useState(false);
  const isVetted =
    userVerificationLevel === 'VETTED' || userVerificationLevel > 0;
  const ClientFallBackComponent = () => (
    <ClientFallBack
      csrfToken={csrfToken}
      userFirstName={userFirstName}
      userFullName={userFullName}
      userProfileCode={userProfileCode}
      userProfileImg={userProfileImg}
      userOrganisationRole={userOrganisationRole}
      isVetted={isVetted}
      isMenuOpen={isMenuOpen}
      toggleMenu={toggleMenu}
      isExploreEnabled={isExploreEnabled}
    />
  );
  return (
    <QueryRendererContainer
      environment={relayEnvironment}
      query={ClientRendererQuery}
      renderLoading={ClientFallBackComponent}
      renderError={ClientFallBackComponent}
      render={data => (
        <Client
          data={data}
          csrfToken={csrfToken}
          userFirstName={userFirstName}
          userFullName={userFullName}
          userProfileCode={userProfileCode}
          userOrganisationRole={userOrganisationRole}
          isVetted={isVetted}
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          isExploreEnabled={isExploreEnabled}
        />
      )}
    />
  );
};

export { ClientRenderer };

export default withRelayEnvironment(ClientRenderer);
