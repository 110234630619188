//
import React from 'react';

/* eslint-disable */
export const ErrorIllustration = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 872 189.2"
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
	.st0-errorillustration{fill:#87A2B9;}
  `,
        }}
      />
      <g>
        <g>
          <path
            className="st0-errorillustration"
            d="M213.9,169.9c-4.4,0-8.6-2.6-10.4-6.9c-2.4-5.7,0.4-12.3,6.1-14.6c5.7-2.4,12.3,0.4,14.6,6.1l0,0
			c2.4,5.7-0.4,12.3-6.1,14.6C216.7,169.6,215.3,169.9,213.9,169.9z M213.8,149.5c-1.2,0-2.4,0.2-3.5,0.7c-4.7,1.9-6.9,7.3-5,12
			c1.9,4.7,7.3,6.9,12,5c4.7-1.9,6.9-7.3,5-12l0,0C220.9,151.6,217.5,149.5,213.8,149.5z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st0-errorillustration"
            d="M224.6,189.2c-0.1,0-0.2,0-0.2-0.1c-0.6,0.1-1.1,0.1-1.6,0c-0.6-0.1-1.6-0.4-4.2-6.6c-0.1-0.3-0.3-0.7-0.4-1
			c-2.9,0.5-5.9,0.5-8.7,0c-2.9,7.2-4,7.4-4.6,7.6c-0.5,0.1-1,0.1-1.6,0c-0.1,0.1-0.3,0.1-0.4,0l-0.6-0.2c-0.1,0-0.4-0.1-0.8-0.3
			c-0.4-0.2-0.8-0.3-0.8-0.4L200,188c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.4-0.8-0.7-1.1-1.1c0-0.1-0.1-0.2-0.1-0.2
			c-0.2-0.5-0.7-1.6,2.3-8.4c-2.4-1.6-4.5-3.7-6.1-6.1c-1.6,0.8-4.2,2-6.1,2.4c-1.9,0.4-3.7-0.7-4.6-3l-0.1-0.2
			c-1-2.3-0.5-4.3,1.2-5.4c1.7-1.1,4.5-2.1,6-2.6c-0.5-2.8-0.5-5.8,0-8.6c-1.7-0.6-4.3-1.5-6-2.6c-0.6-0.4-1.1-1-1.4-1.6
			c-0.6-1.4-0.2-2.9,0.3-3.9c0.9-2.4,2.7-3.5,4.7-3.1c1.9,0.4,4.5,1.6,6.1,2.4c1.6-2.4,3.7-4.5,6.1-6.1c-0.4-0.8-0.9-1.8-1.3-2.9
			c-0.5-1.2-0.9-2.3-1.1-3.2c-0.4-2,0.7-3.7,3.1-4.7c2.3-1,4.4-0.6,5.5,1.1c0.5,0.7,1,1.7,1.5,3c0.4,0.9,0.7,1.9,1.1,3
			c2.8-0.5,5.8-0.5,8.6,0c0.6-1.7,1.5-4.3,2.6-6c1.1-1.7,3.1-2.1,5.5-1.1c1,0.4,2.4,1.2,2.9,2.5c0.3,0.7,0.3,1.4,0.2,2.1
			c-0.4,2-1.7,4.7-2.4,6.1c2.4,1.6,4.5,3.7,6.1,6.1c7.1-3.1,8.1-2.4,8.6-2.1c0.4,0.2,0.8,0.6,1.1,1.1c0.1,0,0.2,0.1,0.3,0.3l0.3,0.6
			c0,0.1,0.2,0.4,0.4,0.8c0.2,0.4,0.3,0.7,0.3,0.8l0.2,0.7c0,0.1,0,0.3,0,0.4c0.1,0.6,0.1,1.1,0,1.6c-0.1,0.6-0.4,1.7-7.6,4.6
			c0.5,2.9,0.5,5.9,0,8.7c6.8,2.7,7.3,3.8,7.5,4.3c0,0.1,0.1,0.2,0.1,0.3c0.1,0.5,0.1,1,0,1.6c0.1,0.1,0.1,0.3,0,0.4l-0.2,0.6l0,0
			c0,0-0.1,0.4-0.3,0.8c-0.2,0.5-0.3,0.8-0.3,0.8l-0.3,0.6c-0.1,0.1-0.2,0.2-0.3,0.3c-0.4,0.5-0.7,0.8-1.1,1.1
			c-0.5,0.3-1.5,0.9-8.6-2.1c-1.6,2.4-3.8,4.5-6.2,6.2c0.1,0.3,0.3,0.6,0.4,0.9c2.6,6.2,2.1,7.1,1.7,7.6c-0.2,0.4-0.6,0.8-1.1,1.1
			c0,0.1-0.1,0.2-0.3,0.3l-0.6,0.3c-0.1,0-0.4,0.2-0.8,0.4c-0.4,0.2-0.7,0.3-0.8,0.3l-0.7,0.2C224.7,189.2,224.6,189.2,224.6,189.2z
			 M218.5,180.5c0.2,0,0.4,0.1,0.5,0.3c0.1,0.4,0.3,0.8,0.5,1.3c2.4,5.8,3.2,6,3.5,6c0.4,0.1,0.9,0.1,1.4,0c0.1,0,0.2,0,0.3,0
			l0.3-0.1c0.1,0,0.4-0.1,0.8-0.3c0.4-0.2,0.7-0.3,0.8-0.3c0,0,0,0,0,0l0.3-0.2c0-0.1,0.1-0.2,0.2-0.2c0.5-0.3,0.8-0.7,1-1
			c0.1-0.2,0.6-1-1.8-6.7c-0.2-0.5-0.4-0.9-0.6-1.3c-0.1-0.2,0-0.5,0.2-0.6c2.6-1.7,4.8-3.9,6.5-6.5c0.1-0.2,0.4-0.3,0.6-0.2
			c4.5,2,7.3,2.8,8,2.3c0.3-0.2,0.7-0.6,1-1.1c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.3c0,0,0.2-0.3,0.3-0.8c0.2-0.4,0.3-0.7,0.3-0.8
			l0.1-0.4c0-0.1,0-0.2,0-0.3c0.1-0.6,0.1-1.1,0-1.4c0,0,0-0.1,0-0.1c-0.3-0.8-2.9-2.2-7.3-3.9c-0.2-0.1-0.4-0.3-0.3-0.6
			c0.6-3,0.6-6.2,0-9.2c-0.1-0.2,0.1-0.5,0.3-0.6c4.5-1.8,7.2-3.2,7.3-4c0.1-0.4,0.1-0.9,0-1.4c0-0.1,0-0.2,0-0.3l-0.1-0.3
			c0-0.1-0.1-0.4-0.3-0.8c-0.2-0.4-0.3-0.7-0.3-0.8l-0.2-0.3c-0.1,0-0.2-0.1-0.2-0.2c-0.3-0.5-0.7-0.8-1-1c-0.7-0.4-3.6,0.4-8,2.3
			c-0.2,0.1-0.5,0-0.6-0.2c-1.7-2.5-3.9-4.8-6.4-6.4c-0.2-0.1-0.3-0.4-0.2-0.6c0.6-1.2,2-4.2,2.5-6.2c0.1-0.5,0.1-1.1-0.1-1.5
			c-0.4-1-1.5-1.7-2.4-2c-1.1-0.5-3.2-1-4.3,0.7c-1.1,1.8-2.2,4.9-2.6,6.2c-0.1,0.2-0.3,0.4-0.6,0.3c-3-0.6-6.1-0.6-9.1,0
			c-0.2,0.1-0.5-0.1-0.6-0.3c-0.4-1.2-0.8-2.4-1.2-3.4c-0.5-1.2-1-2.1-1.4-2.8c-1.1-1.7-3.1-1.2-4.3-0.7c-1.1,0.5-3,1.5-2.5,3.5
			c0.2,0.8,0.5,1.8,1,3c0.6,1.3,1.2,2.6,1.5,3.3c0.1,0.2,0,0.5-0.2,0.6c-2.5,1.7-4.7,3.9-6.4,6.4c-0.1,0.2-0.4,0.3-0.6,0.2
			c-1.2-0.6-4.2-2.1-6.3-2.5c-2-0.4-3.1,1.4-3.5,2.5c-0.4,0.9-0.7,2.1-0.3,3.1c0.2,0.5,0.5,0.9,1,1.2c1.8,1.1,4.9,2.2,6.2,2.6
			c0.2,0.1,0.4,0.3,0.3,0.6c-0.6,3-0.6,6.1,0,9c0.1,0.2-0.1,0.5-0.3,0.6c-1.3,0.4-4.4,1.5-6.2,2.6c-1.7,1.1-1.2,3.1-0.8,4.2l0.1,0.2
			c0.4,1.1,1.5,2.8,3.5,2.4c2-0.4,4.8-1.8,6.3-2.5c0.2-0.1,0.5,0,0.6,0.2c1.7,2.5,3.9,4.8,6.4,6.4c0.2,0.1,0.3,0.4,0.2,0.6
			c-1.9,4.3-2.7,7.2-2.4,8c0,0,0,0.1,0,0.1c0.2,0.3,0.6,0.7,1.1,1c0.1,0.1,0.1,0.1,0.2,0.2l0.3,0.2c0,0,0.3,0.2,0.8,0.3
			c0.4,0.2,0.7,0.3,0.8,0.3l0.4,0.1c0.1,0,0.2,0,0.3,0c0.6,0.1,1.1,0.1,1.5,0c0.8-0.2,2.2-2.7,4-7.4c0.1-0.2,0.3-0.4,0.6-0.3
			c3,0.6,6.2,0.6,9.1,0C218.4,180.5,218.5,180.5,218.5,180.5z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st0-errorillustration"
            d="M274.1,135.5c-4.2,0-8-3.1-8.6-7.4c-0.7-4.8,2.5-9.2,7.3-10c4.8-0.7,9.2,2.5,10,7.3v0c0.7,4.8-2.5,9.2-7.3,10
			C275,135.5,274.6,135.5,274.1,135.5z M274.1,120c-0.3,0-0.7,0-1,0.1c-3.7,0.6-6.2,4-5.6,7.7c0.6,3.7,4,6.2,7.7,5.6
			c3.7-0.6,6.2-4,5.6-7.7C280.3,122.4,277.4,120,274.1,120z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st0-errorillustration"
            d="M276.7,151.3c-0.1,0-0.2,0-0.3-0.1c-0.5,0-0.8-0.2-1.2-0.3c-0.6-0.3-1.2-1-2-5.7c0-0.2-0.1-0.5-0.1-0.7
			c-2.2-0.1-4.3-0.6-6.3-1.5c-3.4,4.8-4.3,4.7-4.8,4.7c-0.4,0-0.8-0.1-1.2-0.3c-0.1,0-0.2,0-0.3-0.1l-0.4-0.3l0,0
			c-0.2-0.1-0.6-0.4-0.6-0.4c-0.3-0.2-0.5-0.4-0.5-0.4l-0.4-0.3c-0.1-0.1-0.2-0.2-0.2-0.3c-0.3-0.3-0.5-0.7-0.6-1c0-0.1,0-0.1,0-0.2
			c-0.1-0.5-0.2-1.4,3.1-5.8c-1.4-1.6-2.6-3.5-3.3-5.5c-1.2,0.3-3.3,0.7-4.8,0.7c-1.6,0-2.7-1.2-3-3.1c0,0,0-0.1,0-0.1
			c-0.3-2,0.4-3.5,1.9-4c1.5-0.5,3.8-0.8,4.8-0.8c0.1-2.1,0.6-4.2,1.5-6.2c-1.3-0.8-2.9-1.9-3.9-3c-0.4-0.4-0.7-0.9-0.8-1.5
			c-0.1-0.9,0.2-1.9,0.9-2.9c1.1-1.6,2.7-2.2,4.1-1.5c1.3,0.7,3,2,4,2.8c1.6-1.4,3.4-2.5,5.4-3.3c-0.1-0.5-0.3-1.4-0.4-2.3
			c-0.2-1-0.2-1.9-0.2-2.5c0-1.6,1.2-2.7,3.2-3c2-0.3,3.5,0.4,4,1.9c0.2,0.6,0.4,1.5,0.6,2.5c0.1,0.9,0.2,1.8,0.3,2.3
			c2.1,0.1,4.2,0.6,6.2,1.5c0.6-0.9,1.8-2.8,3-3.9c1.2-1.1,2.8-1,4.4,0.2c0.7,0.5,1.6,1.3,1.7,2.5c0.1,0.6,0,1.1-0.3,1.7
			c-0.6,1.3-1.9,2.9-2.8,4c1.4,1.6,2.6,3.5,3.3,5.5c5.8-1,6.4-0.3,6.7,0c0.3,0.3,0.5,0.6,0.7,1c0.1,0.1,0.2,0.2,0.2,0.3l0.1,0.5h0
			c0,0.1,0.1,0.3,0.1,0.7c0.1,0.3,0.1,0.6,0.1,0.6l0,0.5c0,0.1,0,0.2-0.1,0.3c0,0.5-0.1,0.8-0.3,1.2c-0.2,0.4-0.6,1.3-6.4,2.1
			c-0.1,2.2-0.6,4.3-1.5,6.3c4.5,3.2,4.6,4.1,4.7,4.5c0,0.1,0,0.2,0,0.2c0,0.4-0.1,0.8-0.3,1.2c0,0.1,0,0.2-0.1,0.3l-0.3,0.4l0,0
			c-0.1,0.2-0.3,0.5-0.4,0.6c-0.1,0.2-0.3,0.4-0.4,0.5l0,0c0,0,0,0,0,0.1l-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0.3-0.7,0.5-1,0.6
			c-0.5,0.2-1.3,0.4-6-3.1c-1.6,1.4-3.5,2.6-5.5,3.4c0,0.2,0.1,0.4,0.1,0.7c0.7,4.7,0.4,5.5-0.1,6c-0.3,0.3-0.6,0.5-1.1,0.7
			c-0.1,0.1-0.1,0.2-0.3,0.2l-0.5,0.1c0,0-0.3,0.1-0.7,0.1c-0.3,0.1-0.6,0.1-0.6,0.1L276.7,151.3
			C276.7,151.3,276.7,151.3,276.7,151.3z M266.7,141.8c0.1,0,0.1,0,0.2,0c2.1,1,4.4,1.6,6.7,1.6c0.2,0,0.5,0.2,0.5,0.4
			c0,0.3,0.1,0.7,0.2,1.1c0.3,2.1,0.8,4.7,1.4,5c0.3,0.1,0.6,0.2,1,0.2c0.1,0,0.1,0,0.2,0.1l0.2,0c0.1,0,0.3,0,0.6-0.1
			c0.3,0,0.6-0.1,0.6-0.1l0.3-0.1c0-0.1,0.1-0.1,0.2-0.1c0.4-0.2,0.7-0.3,0.9-0.5c0.4-0.4,0.3-2.1-0.1-5.2c0-0.3-0.1-0.6-0.1-0.9
			l0-0.2c0-0.2,0.1-0.5,0.3-0.6c2.2-0.8,4.2-2,5.9-3.6c0.2-0.2,0.5-0.2,0.6,0c4.1,3.1,5.1,3.2,5.4,3.2c0.3-0.1,0.6-0.3,0.9-0.6
			c0.1-0.1,0.1-0.1,0.2-0.1l0.2-0.2l0,0c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.3-0.4c0.2-0.3,0.3-0.4,0.4-0.5l0.2-0.2c0-0.1,0-0.1,0-0.2
			c0.2-0.4,0.3-0.7,0.3-1c-0.1-0.4-0.6-1.3-4.6-4.2c-0.2-0.1-0.3-0.4-0.2-0.6c1-2.1,1.6-4.4,1.6-6.7c0-0.2,0.2-0.4,0.4-0.5
			c5.1-0.7,5.9-1.3,6-1.6c0.1-0.3,0.2-0.6,0.2-1c0-0.1,0-0.2,0.1-0.2l0-0.2c0,0,0-0.3-0.1-0.6c0-0.3-0.1-0.6-0.1-0.6c0,0,0,0,0,0
			l-0.1-0.3c-0.1,0-0.1-0.1-0.1-0.2c-0.1-0.4-0.3-0.7-0.5-0.9c-0.2-0.2-1.2-0.6-6.2,0.3c-0.2,0-0.5-0.1-0.6-0.3
			c-0.8-2.2-2-4.2-3.6-5.9c-0.2-0.2-0.2-0.5,0-0.7c0.8-1,2.3-2.8,2.9-4.1c0.2-0.4,0.2-0.7,0.2-1.1c-0.1-0.9-0.9-1.5-1.3-1.8
			c-0.7-0.5-2.1-1.3-3.1-0.3c-1.2,1.2-2.6,3.4-3,4.1c-0.1,0.2-0.4,0.3-0.6,0.2c-2.1-1-4.3-1.5-6.6-1.6c-0.3,0-0.5-0.2-0.5-0.5
			c0-0.3-0.1-1.5-0.3-2.7c-0.1-1-0.3-1.7-0.5-2.3c-0.5-1.4-2-1.4-2.9-1.2c-0.9,0.1-2.4,0.6-2.4,2c0,0.6,0.1,1.4,0.2,2.4
			c0.2,1.2,0.4,2.3,0.5,2.7c0.1,0.2-0.1,0.5-0.3,0.6c-2.1,0.8-4.2,2-5.8,3.5c-0.2,0.2-0.5,0.2-0.7,0c-1-0.8-2.8-2.3-4.1-3
			c-1.3-0.7-2.4,0.5-2.9,1.2c-0.4,0.5-0.9,1.4-0.7,2.2c0.1,0.4,0.2,0.7,0.5,1c0.8,0.8,2.2,1.9,4.1,3c0.2,0.1,0.3,0.4,0.2,0.6
			c-1,2.1-1.5,4.4-1.6,6.6c0,0.3-0.2,0.5-0.5,0.5c-0.5,0-3.3,0.3-5,0.8c-1.4,0.5-1.4,1.9-1.2,2.8c0,0,0,0.1,0,0.1
			c0.1,0.9,0.6,2.3,2,2.4c1.6,0,4-0.5,5-0.7c0.2-0.1,0.5,0.1,0.6,0.3c0.8,2.1,2,4.2,3.6,5.9c0.2,0.2,0.2,0.5,0,0.6
			c-2.9,3.8-3.2,5-3.2,5.4c0.1,0.3,0.3,0.6,0.6,0.9c0,0.1,0.1,0.1,0.1,0.2l0.2,0.2c0,0,0.2,0.2,0.5,0.4c0.3,0.2,0.5,0.3,0.5,0.4
			l0.2,0.1c0.1,0,0.1,0,0.2,0c0.4,0.2,0.7,0.3,1,0.3c0,0,0,0,0,0c0.3,0,1.2-0.5,4.2-4.7C266.4,141.9,266.5,141.8,266.7,141.8z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st0-errorillustration"
            d="M579.8,66.4c-3.2,0-6.3-1.9-7.7-5c-1.9-4.3,0.1-9.3,4.3-11.1l0,0c2.1-0.9,4.4-1,6.5-0.2
			c2.1,0.8,3.8,2.4,4.7,4.5c1.9,4.3,0,9.3-4.3,11.1C582.1,66.1,581,66.4,579.8,66.4z M577.2,52c-3.2,1.4-4.7,5.2-3.3,8.5
			c1.4,3.2,5.3,4.7,8.5,3.3c3.2-1.4,4.7-5.2,3.3-8.5c-0.7-1.6-2-2.8-3.6-3.4C580.5,51.3,578.8,51.3,577.2,52L577.2,52z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="st0-errorillustration"
            d="M573,80.5c-0.5,0-1.1-0.1-1.7-0.4c-1.8-0.7-2.7-2-2.4-3.5c0.3-1.4,1.1-3.2,1.6-4.4c-1.7-1.1-3.2-2.5-4.4-4.2
			c-0.5,0.3-1.2,0.6-2,1c-0.9,0.4-1.7,0.7-2.3,0.8c-1.5,0.4-2.9-0.5-3.6-2.3c-0.8-1.8-0.5-3.3,0.8-4.2c0.5-0.4,1.3-0.8,2.2-1.2
			c0.8-0.4,1.6-0.6,2.1-0.8c-0.4-2-0.5-4.1-0.1-6.1c-1.1-0.3-3.1-1-4.3-1.8c-1.3-0.8-1.7-2.4-1-4.2c0.3-0.8,0.8-1.8,1.9-2.3
			c0.5-0.2,1.1-0.3,1.6-0.2c1.4,0.3,3.2,1.1,4.4,1.6c1.1-1.7,2.6-3.2,4.2-4.5c-2.1-4.7-2-5.7-1.6-6.3c0.2-0.3,0.5-0.6,0.8-0.9
			c0-0.1,0.1-0.2,0.2-0.2l0.4-0.2c0.1,0,0.3-0.1,0.6-0.3c0.3-0.1,0.5-0.2,0.6-0.3l0.5-0.2c0.1,0,0.2,0,0.4,0c0.4-0.1,0.8-0.1,1.2,0
			c0.6,0.1,1.5,0.7,3.5,5.4c2-0.4,4.2-0.5,6.2-0.2c1.8-5,2.7-5.3,3-5.5c0.1,0,0.1-0.1,0.2-0.1c0.3-0.1,0.7-0.1,1.2,0
			c0.1,0,0.2-0.1,0.3,0l0.5,0.1l0,0c0,0,0.3,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.5,0.2l0,0c0,0,0.1,0,0.1,0l0.4,0.2
			c0.1,0.1,0.2,0.1,0.2,0.3c0.3,0.3,0.6,0.5,0.8,0.8c0.3,0.4,0.8,1.1-1.3,6.3c1.7,1.1,3.3,2.6,4.5,4.3c0.2-0.1,0.4-0.2,0.6-0.3
			c4.2-1.9,5.1-1.7,5.7-1.4c0.3,0.2,0.6,0.4,0.9,0.8c0.1,0,0.2,0.1,0.2,0.2l0.2,0.4c0,0,0,0,0,0c0,0,0.1,0.3,0.3,0.6
			c0.2,0.3,0.2,0.6,0.3,0.6l0.2,0.5c0,0.1,0,0.2,0,0.4c0.1,0.4,0.1,0.8,0,1.2c-0.1,0.6-0.6,1.4-4.8,3.3c-0.2,0.1-0.4,0.2-0.6,0.3
			c0.4,2,0.5,4.1,0.1,6.2c4.8,1.8,5.4,2.6,5.6,3.3c0.1,0.3,0.1,0.7,0,1.2c0,0.1,0.1,0.2,0,0.3l-0.1,0.5c0,0-0.2,0.5-0.2,0.6
			c-0.1,0.3-0.2,0.5-0.2,0.6l-0.2,0.5c-0.1,0.1-0.1,0.2-0.2,0.2c-0.2,0.3-0.5,0.6-0.8,0.8c-0.1,0-0.1,0.1-0.2,0.1
			c-0.4,0.2-1.3,0.5-6.2-1.4c-1.1,1.7-2.6,3.2-4.2,4.4c0.6,1.2,1.5,3,1.8,4.3c0.4,1.5-0.4,2.8-2.2,3.6l-0.4-0.4l0.2,0.5
			c-1.7,0.8-3.3,0.5-4.1-0.8c-0.9-1.3-1.6-3.3-2-4.3c-2,0.4-4.1,0.5-6.1,0.1c-0.4,1.4-1.1,3.2-1.8,4.4c-0.3,0.5-0.7,0.9-1.2,1.1
			C573.8,80.4,573.4,80.5,573,80.5z M566.2,66.9c0.2,0,0.3,0.1,0.4,0.2c1.2,1.8,2.9,3.4,4.7,4.5c0.2,0.1,0.3,0.4,0.2,0.6
			c-0.5,1.1-1.4,3.1-1.7,4.6c-0.3,1.4,1,2.1,1.8,2.4c0.6,0.2,1.5,0.5,2.2,0.2c0.3-0.1,0.6-0.4,0.8-0.7c0.6-0.9,1.2-2.5,1.8-4.6
			c0.1-0.2,0.3-0.4,0.6-0.3c2.2,0.4,4.4,0.3,6.6-0.2c0.3-0.1,0.5,0.1,0.6,0.3c0.2,0.5,1.1,3,2,4.5c0.8,1.1,2.1,0.8,2.9,0.4
			c0,0,0.2-0.1,0.2-0.1c0.7-0.3,1.9-1.1,1.6-2.4c-0.4-1.5-1.5-3.6-1.9-4.5c-0.1-0.2-0.1-0.5,0.2-0.7c1.8-1.2,3.4-2.9,4.5-4.8
			c0.1-0.2,0.4-0.3,0.6-0.2c4.4,1.8,5.5,1.7,5.8,1.6c0.2-0.1,0.5-0.4,0.7-0.8c0-0.1,0.1-0.1,0.1-0.2l0.1-0.2c0-0.1,0.1-0.3,0.2-0.5
			c0.1-0.3,0.2-0.5,0.2-0.6l0.1-0.2c0-0.1,0-0.1,0-0.2c0.1-0.4,0.1-0.7,0-1c-0.1-0.3-0.7-1-5.4-2.7c-0.2-0.1-0.4-0.3-0.3-0.6
			c0.4-2.2,0.4-4.5-0.1-6.6c-0.1-0.2,0.1-0.5,0.3-0.6c0.3-0.1,0.6-0.3,1-0.4c1.9-0.8,4.1-1.9,4.3-2.5c0.1-0.3,0-0.6,0-1
			c0-0.1,0-0.1,0-0.2l-0.1-0.2c0,0-0.1-0.2-0.2-0.5c-0.1-0.3-0.2-0.5-0.2-0.5l-0.1-0.2c-0.1,0-0.1-0.1-0.1-0.1
			c-0.3-0.3-0.5-0.6-0.7-0.7c-0.4-0.3-2,0.2-4.8,1.4c-0.3,0.1-0.6,0.3-0.8,0.4l-0.1,0.1c-0.2,0.1-0.5,0-0.6-0.2
			c-1.2-1.8-2.9-3.4-4.8-4.6c-0.2-0.1-0.3-0.4-0.2-0.6c1.9-4.7,1.7-5.6,1.6-5.8c-0.2-0.2-0.4-0.5-0.7-0.7c-0.1,0-0.1-0.1-0.2-0.2
			l-0.2-0.1l0,0c0,0,0,0-0.1,0c-0.1,0-0.3-0.1-0.5-0.2c-0.3-0.1-0.5-0.2-0.6-0.2l-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.7-0.1-1,0
			c-0.3,0.1-1.1,0.9-2.7,5.3c-0.1,0.2-0.3,0.4-0.6,0.3c-2.2-0.4-4.5-0.3-6.6,0.2c-0.2,0.1-0.5-0.1-0.6-0.3c-1.9-4.5-2.7-5.2-3-5.2
			c-0.3-0.1-0.6,0-1,0c-0.1,0-0.1,0-0.2,0l-0.2,0.1c0,0-0.2,0.1-0.5,0.2c-0.3,0.1-0.5,0.2-0.6,0.2l-0.2,0.1c0,0.1-0.1,0.1-0.1,0.2
			c-0.3,0.2-0.6,0.5-0.7,0.7c-0.1,0.2-0.2,1.2,1.9,5.7c0.1,0.2,0,0.5-0.2,0.6c-1.8,1.3-3.4,2.9-4.5,4.8c-0.1,0.2-0.4,0.3-0.6,0.2
			c-1.1-0.5-3.1-1.4-4.6-1.7c-0.4-0.1-0.7,0-1,0.1c-0.7,0.3-1.1,1.1-1.3,1.7c-0.3,0.8-0.7,2.2,0.5,2.9c1.4,0.8,3.8,1.6,4.5,1.8
			c0.2,0.1,0.4,0.3,0.3,0.6c-0.4,2.2-0.3,4.4,0.2,6.6c0.1,0.2-0.1,0.5-0.3,0.6c-0.4,0.1-1.4,0.5-2.4,1c-0.9,0.4-1.5,0.7-2,1.1
			c-1.2,0.8-0.8,2.2-0.4,2.9c0.3,0.8,1.1,2,2.5,1.7c0.6-0.1,1.3-0.4,2.1-0.8c1-0.5,2-1,2.3-1.2C566,66.9,566.1,66.9,566.2,66.9z"
          />
        </g>
      </g>
      <g>
        <path
          className="st0-errorillustration"
          d="M211.4,84.9c-2,0-4.1-0.8-5.5-2.5c-2.7-3-2.4-7.7,0.7-10.4l20.6-18.2c-0.9-5,0.8-10.1,4.6-13.4
		c3.3-2.9,7.6-4.1,12-3.3c0.6,0.1,1.2,0.4,1.6,0.9c0.3,0.3,0.5,0.7,0.6,1.1c0.3,1,0,2.1-0.8,2.8l-6.2,5.5l0.5,5.6l5.5,1.2l6.2-5.5
		c0.8-0.7,1.9-0.9,2.9-0.5c0.4,0.2,0.7,0.4,1,0.7c0.4,0.5,0.6,1.1,0.7,1.7l0,0c0.2,4.4-1.5,8.6-4.8,11.5c-3.8,3.3-9.1,4.4-13.9,2.8
		l-20.7,18.2C214.9,84.3,213.1,84.9,211.4,84.9z M241.2,38c-3.2,0-6.3,1.1-8.7,3.3c-3.6,3.1-5.2,8-4.2,12.7l0.1,0.3l-21.1,18.6
		c-2.6,2.3-2.9,6.3-0.6,9c2.3,2.6,6.3,2.9,9,0.6l21.1-18.6l0.3,0.1c4.5,1.6,9.6,0.6,13.1-2.5c3.1-2.7,4.7-6.6,4.5-10.6
		c0-0.4-0.2-0.8-0.4-1.1c-0.2-0.2-0.4-0.3-0.6-0.4c-0.6-0.3-1.3-0.1-1.8,0.3l-6.6,5.8l-6.7-1.5l-0.6-6.8l6.6-5.8
		c0.5-0.4,0.7-1.1,0.5-1.8c-0.1-0.3-0.2-0.5-0.4-0.7c-0.3-0.3-0.6-0.5-1-0.6C242.8,38,242,38,241.2,38z"
        />
      </g>
      <g>
        <path
          className="st0-errorillustration"
          d="M211.4,80.9c-0.9,0-1.8-0.4-2.5-1.1c-1.2-1.4-1.1-3.5,0.3-4.7L229.8,57l0.6,1c0.4,0.6,0.8,1.2,1.3,1.7
		c0.5,0.5,1,1,1.5,1.5l0.9,0.7l-20.5,18.1C213,80.6,212.2,80.9,211.4,80.9z M229.4,60.1l-18.8,16.5c-0.5,0.5-0.6,1.3-0.1,1.8
		c0.5,0.5,1.3,0.6,1.8,0.1L231.1,62c-0.3-0.3-0.6-0.6-0.9-0.9C229.9,60.7,229.6,60.4,229.4,60.1z"
        />
      </g>
      <path
        className="st0-errorillustration"
        d="M679.8,35l-2.3-0.5l0.4-1.8c1.5-7.2-0.5-14.4-4.9-19.6c0.1-0.3,0.1-0.5-0.1-0.8c-3.3-3.8-7.7-6.3-12.7-7.3
	c-4.1-0.9-8.4-0.6-12.4,0.7c-0.3,0.1-0.5,0.3-0.5,0.6c-7.2,2.7-12.8,8.8-14.5,16.8l-0.4,1.8l-2.3-0.5c-1.7-0.4-3.4,0.7-3.7,2.4
	l-1.7,8.1c-0.4,1.7,0.7,3.4,2.4,3.7l3.1,0.6l1.5-7.1c0,0,0,0,0,0l1.9-8.9c1.6-7.8,7.1-13.7,14.1-16.3c0.2,0.2,0.5,0.2,0.7,0.1
	C652,6,656,5.8,659.8,6.6c4.6,1,8.7,3.3,11.8,6.8c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0,0.3,0c4.3,5.1,6.3,12,4.8,19l-0.8,3.7
	l-1.7,8.2h0l-1.9,8.9c-1.5,7.2-6.5,13.2-13.3,16c-0.3-1.2-1.2-2.1-2.5-2.4l-0.4-0.1c-1.7-0.4-3.5,0.8-3.8,2.5
	c-0.4,1.7,0.8,3.5,2.5,3.8l0.4,0.1c1.7,0.4,3.5-0.8,3.8-2.5c0-0.2,0.1-0.4,0.1-0.7c7.2-2.9,12.4-9.1,14-16.6l1-4.8l2.3,0.5
	c1.7,0.4,3.4-0.7,3.7-2.4l1.7-8.1C682.6,37.1,681.5,35.4,679.8,35z M629.5,38.5l-2.3-0.5c-1.3-0.3-2.1-1.5-1.8-2.8l1.7-8.1
	c0.3-1.3,1.5-2.1,2.8-1.8l2.3,0.5L629.5,38.5z M658.7,70.7c-0.3,1.3-1.6,2.2-2.9,1.9l-0.4-0.1c-1.3-0.3-2.2-1.6-1.9-2.9
	c0.3-1.3,1.6-2.2,2.9-1.9l0.4,0.1C658.1,68.1,659,69.4,658.7,70.7z M681.5,38.6l-1.7,8.1c-0.3,1.3-1.5,2.1-2.8,1.8l-2.3-0.5l0.7-3.3
	h0l0.6-3.1c0,0,0,0,0,0l1.3-6.4l2.3,0.5C680.9,36.1,681.8,37.3,681.5,38.6z"
      />
      <path
        className="st0-errorillustration"
        d="M648.1,134.9l-6.6-6.6c5.3-7.1,4.8-17.3-1.7-23.8c-3.4-3.4-8-5.3-12.9-5.3c-4.9,0-9.5,1.9-12.9,5.3
	c-3.4,3.4-5.3,8-5.3,12.9c0,4.9,1.9,9.5,5.3,12.9c3.4,3.5,8,5.4,12.9,5.4c3.8,0,7.3-1.1,10.4-3.2l6.7,6.7c0.6,0.6,1.3,0.9,2.1,0.9
	c0.8,0,1.5-0.3,2.1-0.9C649.3,138,649.3,136.1,648.1,134.9z M614.7,129.5c-6.7-6.7-6.7-17.6,0-24.3c3.2-3.2,7.5-5,12.1-5
	c4.6,0,8.9,1.8,12.1,5c6.7,6.7,6.7,17.6,0,24.3c-3.2,3.2-7.5,5-12.1,5C622.3,134.5,617.9,132.7,614.7,129.5z M647.7,138.7
	c-0.5,0.5-1.1,0.7-1.7,0.7s-1.2-0.2-1.7-0.7l-6.7-6.7c0.7-0.5,1.4-1.1,2.1-1.8c0.5-0.5,1-1,1.4-1.6l6.6,6.6
	C648.6,136.3,648.6,137.8,647.7,138.7z"
      />
      <path
        className="st0-errorillustration"
        d="M404.4,85.8l0-2.8l-7.5,0l0-4.2c-7.6,0-13.9,4.5-14.5,10.3c0,0,0,0-0.1,0l-5.2,0c-86.5,0-289.3-0.1-376.8,89.4
	c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3C88.7,90.9,290.8,91,377.1,91l5.2,0c0,0,0,0,0.1,0
	c0.7,5.8,6.9,10.3,14.5,10.3l0-4.2l7.5,0l0-2.8l-7.5,0l0-8.5L404.4,85.8z"
      />
      <path
        className="st0-errorillustration"
        d="M871.7,0.3c-0.4-0.4-1-0.4-1.4,0c-86.9,88.9-289.1,88.8-375.4,88.8l-0.6,0c-0.6-5.8-6.9-10.3-14.5-10.3l0,22.6
	c7.6,0,13.8-4.5,14.5-10.2l0.6,0c1.8,0,3.7,0,5.7,0c89.5,0,285.5-1.8,371.1-89.4C872.1,1.3,872.1,0.7,871.7,0.3z"
      />
    </svg>
  );
};
/* eslint-enable */
