import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import * as E360 from '@expert360';
import { ExpertRoute, Expert } from './ExpertRoute';
import { CONSULTANT_ROLES } from '../ConsultantRoute';
import { CLIENT_ROLES } from '../ClientRoute';
import { NotFoundRoute } from '../NotFoundRoute';

type User = {
  role?: E360.UserRole | null | undefined;
  verificationLevel?: string | null;
  locked?: boolean | null;
} & Expert;

export type Props = {
  user?: User;
  path?: string | string[] | readonly string[];
} & RouteProps;

const isExpert = (role: E360.UserRole) => {
  return CONSULTANT_ROLES.includes(role.toLowerCase());
};

const isClient = (user?: User) => {
  return CLIENT_ROLES.includes(user?.role?.toLowerCase() || '');
};

export const AllowedRoleRoute: React.FC<Props> = ({ user, ...props }) => {
  if (user?.role && isExpert(user.role)) {
    return <ExpertRoute expert={user} {...props} />;
  } else if (isClient(user)) {
    if (user?.verificationLevel === 'VETTED') {
      return <Route {...props} />;
    } else {
      if (['', '/client'].includes(props.path as string)) {
        return <Route {...props} />;
      } else {
        return <NotFoundRoute {...props} />;
      }
    }
  } else {
    return <Route {...props} />;
  }
};
