import Loadable, {
  LoadableComponent,
  LoadingComponentProps,
} from 'react-loadable';
import Messages from './messages';
import Contact from './contact';
import Browse from './browse';
import Explore from './explore';
import FindExperts from './findExperts';
import Reports from './reports';
import Project from './project';
import Projects from './projects';
import ProjectDetails from './projectDetails';
import ClientProjectDetails from './clientProjectDetails';
import ClientProjectList from './clientProjectList';
import PrivateInvitation from './privateInvitation';
import InvitationTerms from './invitationTerms';
import SignUpWelcome from './signUpWelcome';
import ExpertSignUp from './expertSignUp';
import ClientSignUp from './clientSignUp';
import BookMeeting from './bookMeeting';
import ClientClaimAccount from './clientClaimAccount';
import ExpertApplication from './expertApplication';
import InviteWelcome from './inviteWelcome';
import OrganisationDashboard from './organisationDashboard';
import PaymentSettings from './paymentSettings';
import ExploreAdvancedHelp from './exploreAdvancedHelp';
import ExploreAvailability from './exploreAvailability';
import Calendar from './calendar';
import Partners from './partners';
import Login from './login';
import LoginTotp from './loginTotp';
import SsoLogin from './ssoLogin';
import RequestResetPassword from './requestResetPassword';
import ResetPassword from './resetPassword';
import UnsubscribeConfirm from './unsubscribe-confirm';
import UnsubscribeError from './unsubscribe-error';
import AvailabilityError from './availabilityError';
import AvailabilityConfirmation from './availabilityConfirmation';
import FeedbackProjectRecommendation from './feedbackProjectRecommendation';
import Invoices from './invoices';
import ExpertVerification from './expertVerification';
import CreateProject from './createProject';
import EditProject from './editProject';
import TimesheetDetails from './timesheetDetails';
import MultiProjectTimesheets from './multiProjectTimesheets';
import MultiProjectTimesheetsReview from './multiProjectTimesheetsReview';
import PaymentDashboard from './paymentDashboard';
import ApproveContractOffer from './approveContractOffer';
import ReviewTimesheetAnonymously from './reviewTimesheetAnonymously';
import ExpertProfileBuilder from './expertProfileBuilder';
import ContractList from './contractList';
import ExpertsToReview from './expertsToReview';
import ContactDetails from './expertSettings/contactDetails';
import AccountDetails from './expertSettings/accountDetails';
import ChangePassword from './sharedSettings/changePassword';
import Insurance from './expertSettings/insurance';
import PaymentMethod from './expertSettings/paymentMethod';
import Tax from './expertSettings/tax';
import YourBusiness from './expertSettings/yourBusiness';
import MfaSettings from './sharedSettings/mfa';
import Preferences from './expertSettings/preferences';
import ClientContractDetails from './clientContractDetails';
import Legal from './legal';
import Notifications from './notifications';
import ExpertPaymentsProjectSelection from './expert/payments/projectSelection';
import ExpertPaymentsProjectCreation from './expert/payments/projectCreation';
import ExpertPaymentsInvoiceUpload from './expert/payments/invoiceUpload';
import ExpertPaymentsEligibility from './expert/payments/eligibility';
import ExpertAcceptInvite from './expert/acceptInvite';
import ExpertServiceRequest from './expert/serviceRequest';
import ExpertDashboard from './expertDashboard';
import ExpertPaymentsIDVerification from './expert/payments/idVerification';
import ExpertConfirmProjectOpportunityEmail from './expert/confirmProjectOpportunityEmail';
import ExpertOptOutProjectEmail from './expert/optOutProjectEmail';
import Loader from 'shared/Loader';
import { RouteComponentProps } from 'react-router-dom';
import Profile from './profile';
import SetupTotp from './mfa/setupTotp';

export interface PagesType {
  Messages: typeof Messages & LoadableComponent;
  Contact: typeof Contact &
    LoadableComponent &
    React.Component<LoadingComponentProps>;
  Browse: typeof Browse &
    LoadableComponent &
    React.Component<LoadingComponentProps>;
  Explore: typeof Explore &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  FindExpertsPage: typeof FindExperts &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Reports: typeof Reports &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Profile: typeof Profile &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Project: typeof Project &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Projects: typeof Projects &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ProjectDetails: typeof ProjectDetails &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ClientProjectDetails: typeof ClientProjectDetails &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ClientProjectList: typeof ClientProjectList &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  PrivateInvitation: typeof PrivateInvitation &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  InvitationTerms: typeof InvitationTerms &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  SignUpWelcome: typeof SignUpWelcome &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertSignUp: typeof ExpertSignUp &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ClientSignUp: typeof ClientSignUp &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  BookMeeting: typeof BookMeeting &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ClientClaimAccount: typeof ClientClaimAccount &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertApplication: typeof ExpertApplication &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  InviteWelcome: typeof InviteWelcome &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  OrganisationDashboard: typeof OrganisationDashboard &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  PaymentSettings: typeof PaymentSettings &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExploreAdvancedHelp: typeof ExploreAdvancedHelp &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExploreAvailability: typeof ExploreAvailability &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Calendar: typeof Calendar &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Partners: typeof Partners &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Login: typeof Login &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  LoginTotp: typeof LoginTotp &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  SsoLogin: typeof SsoLogin &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  RequestResetPassword: typeof RequestResetPassword &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ResetPassword: typeof ResetPassword &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  UnsubscribeConfirm: typeof UnsubscribeConfirm &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  UnsubscribeError: typeof UnsubscribeError &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  AvailabilityError: typeof AvailabilityError &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  AvailabilityConfirmation: typeof AvailabilityConfirmation &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  FeedbackProjectRecommendation: typeof FeedbackProjectRecommendation &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Invoices: typeof Invoices &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertVerification: typeof ExpertVerification &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  CreateProject: typeof CreateProject &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  EditProject: typeof EditProject &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  TimesheetDetails: typeof TimesheetDetails &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  MultiProjectTimesheets: typeof MultiProjectTimesheets &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  MultiProjectTimesheetsReview: typeof MultiProjectTimesheetsReview &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  PaymentDashboard: typeof PaymentDashboard &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ApproveContractOffer: typeof ApproveContractOffer &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ReviewTimesheetAnonymously: typeof ReviewTimesheetAnonymously &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertProfileBuilder: typeof ExpertProfileBuilder &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ContractList: typeof ContractList &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertsToReview: typeof ExpertsToReview &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ContactDetails: typeof ContactDetails &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  AccountDetails: typeof AccountDetails &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ChangePassword: typeof ChangePassword &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Insurance: typeof Insurance &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  PaymentMethod: typeof PaymentMethod &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Tax: typeof Tax & LoadableComponent & React.Component<RouteComponentProps>;
  YourBusiness: typeof YourBusiness &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  MfaSettings: typeof MfaSettings &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Preferences: typeof Preferences &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ClientContractDetails: typeof ClientContractDetails &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Legal: typeof Legal &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertPaymentsProjectSelection: typeof ExpertPaymentsProjectSelection &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertPaymentsProjectCreation: typeof ExpertPaymentsProjectCreation &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertPaymentsInvoiceUpload: typeof ExpertPaymentsInvoiceUpload &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertPaymentsEligibility: typeof ExpertPaymentsEligibility &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertPaymentsIDVerification: typeof ExpertPaymentsIDVerification &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  Notifications: typeof Notifications &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertDashboard: typeof ExpertDashboard &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertAcceptInvite: typeof ExpertAcceptInvite &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertServiceRequest: typeof ExpertServiceRequest &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertConfirmProjectOpportunityEmail: typeof ExpertConfirmProjectOpportunityEmail &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  ExpertOptOutProjectEmail: typeof ExpertOptOutProjectEmail &
    LoadableComponent &
    React.Component<RouteComponentProps>;
  SetupTotp: typeof SetupTotp &
    LoadableComponent &
    React.Component<RouteComponentProps>;
}

/* tslint:disable */
export const pages = {
  Messages: Loadable({
    loader: async () => import(/* webpackChunkName: "messages" */ './messages'),
    loading: Loader,
  }),
  Contact: Loadable({
    loader: () => import(/* webpackChunkName: "contact" */ './contact'),
    loading: Loader,
  }),
  Browse: Loadable({
    loader: () => import(/* webpackChunkName: "browse" */ './browse'),
    loading: Loader,
  }),
  Explore: Loadable({
    loader: () => import(/* webpackChunkName: "explore" */ './explore'),
    loading: Loader,
  }),
  FindExpertsPage: Loadable({
    loader: () =>
      import(/* webpackChunkName: "findExpertsPage" */ './findExperts'),
    loading: Loader,
  }),
  Reports: Loadable({
    loader: () => import(/* webpackChunkName: "reports" */ './reports'),
    loading: Loader,
  }),
  Profile: Loadable({
    loader: () => import(/* webpackChunkName: "profile" */ './profile'),
    loading: Loader,
  }),
  Project: Loadable({
    loader: () => import(/* webpackChunkName: "project" */ './project'),
    loading: Loader,
  }),
  Projects: Loadable({
    loader: () => import(/* webpackChunkName: "projects" */ './projects'),
    loading: Loader,
  }),
  ProjectDetails: Loadable({
    loader: () =>
      import(/* webpackChunkName: "projectDetails" */ './projectDetails'),
    loading: Loader,
  }),
  ClientProjectDetails: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "clientProjectDetails" */ './clientProjectDetails'
      ),
    loading: Loader,
  }),
  ClientProjectList: Loadable({
    loader: () =>
      import(/* webpackChunkName: "clientProjectList" */ './clientProjectList'),
    loading: Loader,
  }),
  PrivateInvitation: Loadable({
    loader: () =>
      import(/* webpackChunkName: "privateInvitation" */ './privateInvitation'),
    loading: Loader,
  }),
  InvitationTerms: Loadable({
    loader: () =>
      import(/* webpackChunkName: "invitationTerms" */ './invitationTerms'),
    loading: Loader,
  }),
  SignUpWelcome: Loadable({
    loader: () =>
      import(/* webpackChunkName: "SignUpWelcome" */ './signUpWelcome'),
    loading: Loader,
  }),
  BookMeeting: Loadable({
    loader: () => import(/* webpackChunkName: "BookMeeting" */ './bookMeeting'),
    loading: Loader,
  }),
  ExpertSignUp: Loadable({
    loader: () =>
      import(/* webpackChunkName: "expertSignUp" */ './expertSignUp'),
    loading: Loader,
  }),
  ClientSignUp: Loadable({
    loader: () =>
      import(/* webpackChunkName: "clientSignUp" */ './clientSignUp'),
    loading: Loader,
  }),
  ClientClaimAccount: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "clientClaimAccount" */ './clientClaimAccount'
      ),
    loading: Loader,
  }),
  ExpertApplication: Loadable({
    loader: () =>
      import(/* webpackChunkName: "expertApplication" */ './expertApplication'),
    loading: Loader,
  }),
  InviteWelcome: Loadable({
    loader: () =>
      import(/* webpackChunkName: "inviteWelcome" */ './inviteWelcome'),
    loading: Loader,
  }),
  OrganisationDashboard: Loadable({
    loader: () =>
      import(/* webpackChunkName: "organisation" */ './organisationDashboard'),
    loading: Loader,
  }),
  PaymentSettings: Loadable({
    loader: () => import(/* webpackChunkName: "payment" */ './paymentSettings'),
    loading: Loader,
  }),
  ExploreAdvancedHelp: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "explore-advanced-help" */ './exploreAdvancedHelp'
      ),
    loading: Loader,
  }),
  ExploreAvailability: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "explore-availability" */ './exploreAvailability'
      ),
    loading: Loader,
  }),
  Calendar: Loadable({
    loader: () => import(/* webpackChunkName: "calendar" */ './calendar'),
    loading: Loader,
  }),
  Partners: Loadable({
    loader: () => import(/* webpackChunkName: "partners" */ './partners'),
    loading: Loader,
  }),
  Login: Loadable({
    loader: () => import(/* webpackChunkName: "login" */ './login'),
    loading: Loader,
  }),
  LoginTotp: Loadable({
    loader: () => import(/* webpackChunkName: "login/totp" */ './loginTotp'),
    loading: Loader,
  }),
  SsoLogin: Loadable({
    loader: () => import(/* webpackChunkName: "ssoLogin" */ './ssoLogin'),
    loading: Loader,
  }),
  RequestResetPassword: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "requestResetPassword" */ './requestResetPassword'
      ),
    loading: Loader,
  }),
  ResetPassword: Loadable({
    loader: () =>
      import(/* webpackChunkName: "resetPassword" */ './resetPassword'),
    loading: Loader,
  }),
  UnsubscribeConfirm: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "unsubscribe-confirm" */ './unsubscribe-confirm'
      ),
    loading: Loader,
  }),
  UnsubscribeError: Loadable({
    loader: () =>
      import(/* webpackChunkName: "unsubscribe-error" */ './unsubscribe-error'),
    loading: Loader,
  }),
  AvailabilityError: Loadable({
    loader: () =>
      import(/* webpackChunkName: "availabilityError" */ './availabilityError'),
    loading: Loader,
  }),
  AvailabilityConfirmation: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "availabilityConfirmation" */ './availabilityConfirmation'
      ),
    loading: Loader,
  }),
  FeedbackProjectRecommendation: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "feedback-project-recommendation" */ './feedbackProjectRecommendation'
      ),
    loading: Loader,
  }),
  Invoices: Loadable({
    loader: () => import(/* webpackChunkName: "invoices" */ './invoices'),
    loading: Loader,
  }),
  ExpertVerification: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "expertVerification" */ './expertVerification'
      ),
    loading: Loader,
  }),
  CreateProject: Loadable({
    loader: () =>
      import(/* webpackChunkName: "create-project" */ './createProject'),
    loading: Loader,
  }),
  EditProject: Loadable({
    loader: () =>
      import(/* webpackChunkName: "edit-project" */ './editProject'),
    loading: Loader,
  }),
  TimesheetDetails: Loadable({
    loader: () =>
      import(/* webpackChunkName: "timesheet-details" */ './timesheetDetails'),
    loading: Loader,
  }),
  MultiProjectTimesheets: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "multi-project-timesheets" */ './multiProjectTimesheets'
      ),
    loading: Loader,
  }),
  MultiProjectTimesheetsReview: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "multi-project-timesheets-review" */ './multiProjectTimesheetsReview'
      ),
    loading: Loader,
  }),
  PaymentDashboard: Loadable({
    loader: () =>
      import(/* webpackChunkName: "payment-dashboard" */ './paymentDashboard'),
    loading: Loader,
  }),
  ApproveContractOffer: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "review-timesheet-anonymously" */ './approveContractOffer'
      ),
    loading: Loader,
  }),
  ReviewTimesheetAnonymously: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "review-timesheet-anonymously" */ './reviewTimesheetAnonymously'
      ),
    loading: Loader,
  }),
  ExpertProfileBuilder: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "expert-profile-builder" */ './expertProfileBuilder'
      ),
    loading: Loader,
  }),
  ContractList: Loadable({
    loader: () =>
      import(/* webpackChunkName: "contract-list" */ './contractList'),
    loading: Loader,
  }),
  ExpertsToReview: Loadable({
    loader: () =>
      import(/* webpackChunkName: "experts-to-review" */ './expertsToReview'),
    loading: Loader,
  }),
  ContactDetails: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "settings-contact-details" */ './expertSettings/contactDetails'
      ),
    loading: Loader,
  }),
  AccountDetails: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "settings-account-details" */ './expertSettings/accountDetails'
      ),
    loading: Loader,
  }),
  Insurance: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "settings-insurance" */ './expertSettings/insurance'
      ),
    loading: Loader,
  }),
  PaymentMethod: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "settings-payment-method" */ './expertSettings/paymentMethod'
      ),
    loading: Loader,
  }),
  Tax: Loadable({
    loader: () =>
      import(/* webpackChunkName: "settings-tax" */ './expertSettings/tax'),
    loading: Loader,
  }),
  YourBusiness: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "settings-your-business" */ './expertSettings/yourBusiness'
      ),
    loading: Loader,
  }),
  MfaSettings: Loadable({
    loader: () =>
      import(/* webpackChunkName: "settings-mfa" */ './sharedSettings/mfa'),
    loading: Loader,
  }),
  Preferences: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "settings-preferences" */ './expertSettings/preferences'
      ),
    loading: Loader,
  }),
  ChangePassword: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "settings-change-password" */ './sharedSettings/changePassword'
      ),
    loading: Loader,
  }),
  ClientContractDetails: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "client-contract-details" */ './clientContractDetails'
      ),
    loading: Loader,
  }),
  Legal: Loadable({
    loader: () => import(/* webpackChunkName: "legalPage" */ './legal'),
    loading: Loader,
  }),
  ExpertPaymentsProjectSelection: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "expertPaymentsProjectSelectionPage" */ './expert/payments/projectSelection'
      ),
    loading: Loader,
  }),
  ExpertPaymentsProjectCreation: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "expertPaymentsProjectCreationPage" */ './expert/payments/projectCreation'
      ),
    loading: Loader,
  }),
  ExpertPaymentsInvoiceUpload: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "expertPaymentsInvoiceUploadPage" */ './expert/payments/invoiceUpload'
      ),
    loading: Loader,
  }),
  ExpertPaymentsEligibility: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "expertPaymentsEligibilityPage" */ './expert/payments/eligibility'
      ),
    loading: Loader,
  }),
  ExpertPaymentsIDVerification: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "expertPaymentsIDVerificationPage" */ './expert/payments/idVerification'
      ),
    loading: Loader,
  }),
  Notifications: Loadable({
    loader: () =>
      import(/* webpackChunkName: "notificationsPage" */ './notifications'),
    loading: Loader,
  }),
  ExpertDashboard: Loadable({
    loader: () =>
      import(/* webpackChunkName: "expertDashboardPage" */ './expertDashboard'),
    loading: Loader,
  }),
  ExpertAcceptInvite: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "expertAcceptInvite" */ './expert/acceptInvite'
      ),
    loading: Loader,
  }),
  ExpertServiceRequest: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "expertServiceRequest" */ './expert/serviceRequest'
      ),
    loading: Loader,
  }),
  ExpertConfirmProjectOpportunityEmail: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "confirm-project-opportunity-email" */ './expert/confirmProjectOpportunityEmail'
      ),
    loading: Loader,
  }),
  ExpertOptOutProjectEmail: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "confirm-project-opportunity-email" */ './expert/optOutProjectEmail'
      ),
    loading: Loader,
  }),
  SetupTotp: Loadable({
    loader: () =>
      import(/* webpackChunkName: "setup-totp" */ './mfa/setupTotp'),
    loading: Loader,
  }),
};
