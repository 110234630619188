import React from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import logo from './expert360_logo.svg';

const Logo = ({ location, whitelabelImage, referringCompanyImageUrl }) => {
  const { pathname, search } = location;
  const { company = '' } = queryString.parse(search);
  const { source = '' } = queryString.parse(search);

  const logoImageUrl = whitelabelImage
    ? whitelabelImage
    : referringCompanyImageUrl;
  /*
    XXX:
    This displays a hard-coded logo for IAG users on the login page.
    IAG is currently the only org that requires this feature (whitelabel logo on the login page).
    IAG uses the path /login?company=IAG to log in.

    Long-term, a subdomain and fetching this logo from the DB would be more ideal (i.e. iag.expert360.com).
  */
  if (pathname === '/login' && company.toUpperCase() === 'IAG') {
    return (
      <img
        className="l-header__logo__img"
        style={{ maxHeight: '60px' }}
        src="https://x360-images.s3.amazonaws.com/org-logos/iag.svg"
        alt="Expert360"
      />
    );
  }

  if (pathname === '/sign-up/expert' && source.toUpperCase() === 'PWC') {
    return (
      <img
        className="l-header__logo__img"
        style={{ maxHeight: '60px' }}
        src="https://x360-images.s3.amazonaws.com/org-logos/pwc_white.png"
        alt="Expert360"
      />
    );
  }

  /*
    Whitelabel logo for logged in users.
    This feature is required by multiple orgs (not just IAG), so this is fetched from the DB.
    OR 
    Referring company logo 
  */
  if (logoImageUrl) {
    return (
      <img
        className="l-header__logo__img"
        style={{ maxHeight: '60px' }}
        src={logoImageUrl}
        alt="Expert360"
      />
    );
  }

  // E360 logo
  return <img className="l-header__logo__img" src={logo} alt="Expert360" />;
};

export { Logo };
export default withRouter(Logo);
