import React from 'react';
import { RouteProps } from 'react-router-dom';
import { RoleRoute } from './RoleRoute';
import { ADMIN_ROLES } from './AdminRoute';

export const CONSULTANT_ROLES = ['expert', 'consultant'];

export type Props = {
  excludeAdmin?: boolean;
  path?: string | string[];
} & RouteProps;

export const ConsultantRoute: React.FC<Props> = ({
  excludeAdmin = false,
  ...props
}) => {
  const roles = excludeAdmin
    ? CONSULTANT_ROLES
    : [...CONSULTANT_ROLES, ...ADMIN_ROLES];

  return <RoleRoute roles={roles} {...props} />;
};
