import * as E360 from '@expert360';

export const statusText = {
  [E360.ProjectState.New]: 'New',
  [E360.ProjectState.Open]: 'Open',
  [E360.ProjectState.Paused]: 'Applications Paused',
  [E360.ProjectState.OnHold]: 'On Hold',
  [E360.ProjectState.Draft]: 'Draft',
  [E360.ProjectState.Hired]: 'Contract Awarded',
  [E360.ProjectState.Cancelled]: 'Cancelled',
  [E360.ProjectState.Closed]: 'Completed',
  [E360.ProjectState.Declined]: 'Declined',
  UNLISTED: 'Direct Engagement',
  INVITATION_ONLY: 'Invitation-only',
};

export const isUnlisted = (project: Partial<E360.Project>) =>
  project && project.unlisted;

export const isInvitationOnly = (project: Partial<E360.Project>) =>
  project &&
  project.privacy === E360.ProjectPrivacy.Private &&
  (project.state === E360.ProjectState.New ||
    project.state === E360.ProjectState.Open) &&
  !project.unlisted;

export const statusTextForAudience = (
  project: Partial<E360.Project>
): string | null => {
  if (!project || !project.state) return null;
  if (isUnlisted(project)) return statusText.UNLISTED;
  if (isInvitationOnly(project) && project.isSharedWithPrivateCloud)
    return null;
  if (isInvitationOnly(project)) return statusText.INVITATION_ONLY;
  return statusText[project.state] || null;
};
