import React from 'react';
import { ErrorPage } from 'utils';
import * as Logger from 'utils/logger';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    Logger.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="l-main-content-outer">
          <main role="main" className="l-main-content">
            <ErrorPage status="genericFrontendError" />
          </main>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
