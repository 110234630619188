import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
// importing specifically from the Illustration component to avoid SCSS import errors
import { ErrorIllustration } from 'shared/Illustration';
import GetInTouchButton from './GetInTouchButton';

interface Contents {
  title: string;
  content: string;
}
interface StatusTypes {
  [code: string]: Contents;
}

interface HelpMessageProps {
  status: string;
  match: any;
}

const statusTypes: StatusTypes = {
  '500': {
    title: 'Something went wrong',
    content: 'Something went wrong!',
  },
  '404': {
    title: 'Page not found',
    content: 'The page you requested does not exist',
  },
  auth: {
    title: 'Please log in',
    content: 'Please log in to view this page',
  },
  genericFrontendError: {
    title: 'Something went wrong',
    content: 'Something went wrong!',
  },
};

const HelpMessage = ({ status, match = null }: HelpMessageProps) => {
  if (status === 'auth' && match) {
    return (
      <Fragment>
        Please <a href={`/login?next=${match.url}`}>log in</a> to view this
        page.
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        Try going back to our <a href="/">homepage</a>, <br /> or{' '}
        <span className="u-display-block-from-745">
          please <GetInTouchButton /> with the Expert360 team if you need help.
        </span>
      </Fragment>
    );
  }
};

const ErrorPage = ({ status = '500', match = null }) => {
  const { title, content } = statusTypes[status];
  return (
    <div className="u-s-mb-large">
      <div className="l-container l-container--slim">
        <div className="l-strap">
          <div className="l-container l-container--narrow">
            <Helmet>
              <title>{title} - Expert360</title>
              <meta name="robots" content="noindex" />
            </Helmet>

            <div className="u-text-align-center">
              <h1 className="heading heading--size-large u-s-mb-small">
                {content}
              </h1>
              <div className="u-s-mb-large">
                <ErrorIllustration />
              </div>
              <p>
                <HelpMessage status={status} match={match} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  status: PropTypes.string,
};

export default ErrorPage;
