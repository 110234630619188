import React, { useState } from 'react';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import cx from 'classnames';

const Guest = ({ hideCta }) => {
  const [isMenuOpen, toggleMenu] = useState(false);

  return (
    <React.Fragment>
      <div className="l-header__nav-trigger">
        <button
          type="button"
          className="c-icon-only-button c-icon-only-button--menu c-burger-menux"
          aria-haspopup="true"
          onClick={() => toggleMenu(!isMenuOpen)}
        >
          <span />
          <span className="u-hide-visually">Open the site menu</span>
        </button>
      </div>
      {hideCta ? null : (
        <div
          className={cx('l-header__nav js-nav-target', {
            'is-visible': isMenuOpen,
          })}
        >
          <div className="l-header__nav__action">
            <ul className="o-list-inline-from-1020">
              <li className="l-header__nav__item">
                <a href="/login" className="l-header__nav__item__link">
                  Log In
                </a>
              </li>

              <li className="l-header__nav__item">
                <a
                  href="/sign-up"
                  className="l-header__nav__item__link"
                  id="nav-sign-up"
                >
                  Sign Up
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Guest;
