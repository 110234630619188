import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { withTracking } from '@expert360/tracking';
import Logout from './Logout';
import { useFeatureFlag } from 'hooks/useFeatureFlags';
import * as UI from '@expert360/ui';
import { ROUTES } from 'utils/constants';

const ExpertFallback = ({
  isProfileComplete,
  userFirstName,
  userFullName,
  userProfileCode,
  userProfileImg,
  tracking,
  isMenuOpen,
  toggleMenu,
  isVerified,
  isMarketplaceMember,
  referringCompany,
}) => {
  return (
    <React.Fragment>
      <div className="l-header__nav-trigger l-flex-grid">
        <div className="l-header__nav__item l-flex-grid__item">
          <button
            type="button"
            className="c-icon-only-button c-icon-only-button--menu c-burger-menux js-nav-trigger"
            onClick={() => toggleMenu(!isMenuOpen)}
          >
            <span />
            <span className="u-hide-visually">Open the site menu</span>
          </button>
        </div>
      </div>
      <div
        className={cx('l-header__nav', {
          'is-visible': isMenuOpen,
        })}
      >
        <nav className="l-header__nav__main" aria-hidden="false">
          <ul className="o-list-inline-from-1020">
            {isProfileComplete ? (
              <React.Fragment>
                <li className="l-header__nav__item">
                  <NavLink
                    to={ROUTES.expert.dashboard}
                    className="l-header__nav__item__link"
                    activeClassName="is-active"
                    onClick={() => toggleMenu(false)}
                  >
                    My dashboard
                  </NavLink>
                </li>
                <li className="l-header__nav__item">
                  <NavLink
                    to="/expert/project/pending"
                    exact={false}
                    className="l-header__nav__item__link"
                    activeClassName="is-active"
                    onClick={() => toggleMenu(false)}
                  >
                    My Projects
                  </NavLink>
                </li>
                <li className="l-header__nav__item">
                  <NavLink
                    to="/expert/invoices/create"
                    exact={false}
                    className="l-header__nav__item__link"
                    activeClassName="is-active"
                    onClick={() => {
                      toggleMenu(false);
                      tracking.trackAction('Clicked', {
                        Title: 'Create Invoice',
                        Location: 'Top Nav',
                      });
                    }}
                  >
                    Payments
                  </NavLink>
                </li>
              </React.Fragment>
            ) : isVerified ? (
              <li className="l-header__nav__item">
                <a href="/profile/edit" className="l-header__nav__item__link">
                  Profile Setup
                </a>
              </li>
            ) : null}
          </ul>
        </nav>
        <div className="l-header__nav__action">
          <ul className="o-list-inline-from-1020 l-flex-grid">
            <li
              className="l-header__nav__item l-header__nav__item--account l-flex-grid__item l-flex-grid__item--align-middle u-one-whole-up-to-1019"
              role="menu"
              aria-hidden="true"
            >
              <button className="l-header__nav__item__link o-button avatar">
                <div className="u-position-relative">
                  <div className="u-circle u-position-top-left">
                    <UI.Avatar
                      name={userFullName}
                      size={UI.ESizes.Small}
                      imageUrl={userProfileImg}
                    />
                  </div>
                  <div className="u-s-ml-base">
                    &nbsp;
                    <span className="u-hide-from-1019 u-s-pl u-position-absolute">
                      {userFirstName}
                    </span>
                  </div>
                </div>
              </button>
              <div
                className="l-header__nav__sub-nav l-header__nav__sub-nav--account l-header__nav__sub-nav--right"
                aria-hidden="true"
              >
                <ul>
                  {isProfileComplete ? (
                    <React.Fragment>
                      <li className="l-header--sub-nav-text">My Account</li>
                      <li>
                        <NavLink
                          to={`/profile/${userProfileCode}`}
                          exact={false}
                          className="l-header__sub-nav-item"
                          activeClassName="is-active"
                          onClick={() => toggleMenu(false)}
                        >
                          View profile
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/profile/edit#profile"
                          exact={false}
                          className="l-header__sub-nav-item"
                          activeClassName="is-active"
                          onClick={() => toggleMenu(false)}
                        >
                          Edit profile
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/settings/contact-details"
                          exact={false}
                          className="l-header__sub-nav-item"
                          activeClassName="is-active"
                          onClick={() => toggleMenu(false)}
                        >
                          Settings
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/expert/service-request"
                          exact={false}
                          className="l-header__sub-nav-item"
                          activeClassName="is-active"
                          onClick={() => toggleMenu(false)}
                        >
                          Service request
                        </NavLink>
                      </li>
                    </React.Fragment>
                  ) : null}
                  {isMarketplaceMember ? (
                    <li>
                      <a
                        href="https://help.expert360.com/en/collections/1419837-expert-guides"
                        className="l-header__sub-nav-item"
                        tabIndex="0"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Resources
                      </a>
                    </li>
                  ) : null}

                  {isMarketplaceMember &&
                  isVerified &&
                  !['LPSNZ', 'LPS Mesh'].includes(referringCompany) ? (
                    <li>
                      <NavLink
                        to="/partners"
                        exact={false}
                        className="l-header__sub-nav-item"
                        activeClassName="is-active"
                        onClick={() => toggleMenu(false)}
                      >
                        Community Partnerships
                      </NavLink>
                    </li>
                  ) : null}

                  <li>
                    <Logout />
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTracking(ExpertFallback);
