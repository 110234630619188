import React from 'react';
import { RouteProps } from 'react-router-dom';
import { RoleRoute } from './RoleRoute';
import { ADMIN_ROLES } from './AdminRoute';

export const CLIENT_ROLES = ['client'];

export const ORGANISATION_ROLES = ['admin', 'member', 'limited'];

export type Props = {
  excludeAdmin?: boolean;
  path?: string | string[];
  excludedOrganisationRoles?: string[];
} & RouteProps;

export const ClientRoute: React.FC<Props> = ({
  excludeAdmin = false,
  excludedOrganisationRoles = [],
  ...props
}) => {
  const roles = excludeAdmin ? CLIENT_ROLES : [...CLIENT_ROLES, ...ADMIN_ROLES];
  const organisationRoles = ORGANISATION_ROLES.filter(
    role => !excludedOrganisationRoles.includes(role)
  );

  return (
    <RoleRoute roles={roles} organisationRoles={organisationRoles} {...props} />
  );
};
