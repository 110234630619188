import React from 'react';

export type Feedback = {
  show?: boolean;
  timeout?: number;
  type: 'success' | 'error' | 'info';
  message: string;
};

export type RenderFeedbackType = (feedback: Feedback) => void;

export default React.createContext<RenderFeedbackType>(() => {});
