import { BootstrapData } from 'types/bootstrap';

const getBootstrapDetails = (): Promise<BootstrapData> =>
  fetch('/bootstrap', {
    credentials: 'same-origin',
  }).then(response => {
    if (!response.ok || response.status >= 300) {
      throw Error(`Error fetching bootstrap details: ${response.statusText}`);
    } else {
      return response.json() as Promise<BootstrapData>;
    }
  });

export default getBootstrapDetails;
