import { interpolate } from './routes/interpolate';
import * as PATHS from './routes/paths';

export const admin = {
  ...PATHS.admin,
  editProject: interpolate<{ slug: string }>(PATHS.admin.editProject),
};

export const browse = {
  ...PATHS.browse,
};

export const calendar = {
  ...PATHS.calendar,
};

export const client = {
  ...PATHS.client,
  approveContractOffer: interpolate<{ slug: string }>(
    PATHS.client.approveContractOffer
  ),
  reviewTimesheetAnonymously: interpolate<{ slug: string }>(
    PATHS.client.reviewTimesheetAnonymously
  ),
  editProject: interpolate<{ slug: string }>(PATHS.client.editProject),
  projectDetails: interpolate<{ slug: string }>(PATHS.client.projectDetails),
  projectExpertsToReview: interpolate<{ code: string }>(
    PATHS.client.projectExpertsToReview
  ),
  projectExpertsToReviewSelected: interpolate<{ code: string; slug: string }>(
    PATHS.client.projectExpertsToReviewSelected
  ),
  projectTimesheets: interpolate<{ slug: string }>(
    PATHS.client.projectTimesheets
  ),
  projectContracts: interpolate<{ code: string }>(
    PATHS.client.projectContracts
  ),
  projectContractsNew: interpolate<{ slug: string }>(
    PATHS.client.projectContractsNew
  ),
  projectOffers: interpolate<{ slug: string }>(PATHS.client.projectOffers),
  projectPending: interpolate<{ slug: string }>(PATHS.client.projectPending),
  projectActive: interpolate<{ slug: string }>(PATHS.client.projectActive),
  projectTimesheetDetails: interpolate<{ invoiceCode: string }>(
    PATHS.client.projectTimesheetDetails
  ),
  contractDetails: interpolate<{ code: string; slug: string }>(
    PATHS.client.contractDetails
  ),
};

export const contacts = {
  ...PATHS.contacts,
};

export const contracts = {
  ...PATHS.contracts,
  show: interpolate<{ code: string }>(PATHS.contracts.show),
  offer: interpolate<{ code: string; slug: string }>(PATHS.contracts.offer),
};

export const expert = {
  ...PATHS.expert,
  activeProjects: interpolate<{ tab: string }>(PATHS.expert.activeProjects),
  archivedProjects: interpolate<{ tab: string; subTab: string }>(
    PATHS.expert.archivedProjects
  ),
  projectDetails: interpolate<{
    option: 'view' | 'invoices' | 'offer' | 'onboarding';
    slug: string | number;
  }>(PATHS.expert.projectDetails),
  contractDetails: interpolate<{
    projecthash: string;
    contractcode: string;
  }>(PATHS.expert.contractDetails),
  invite: interpolate<{ token: string }>(PATHS.expert.invite),
  confirmProjectOpportunityEmail: interpolate<{ slug: string }>(
    PATHS.expert.confirmProjectOpportunityEmail
  ),
  multiProjectTimesheets: PATHS.expert.multiProjectTimesheets,
  multiProjectTimesheetsReview: PATHS.expert.multiProjectTimesheetsReview,
};

export const explore = {
  ...PATHS.explore,
};

export const findExperts = {
  ...PATHS.findExperts,
};

export const feedback = {
  ...PATHS.feedback,
  projectClientRating: interpolate<{ hash: string }>(
    PATHS.feedback.projectClientRating
  ),
};

export const fms = {
  ...PATHS.fms,
};

export const login = {
  ...PATHS.login,
};

export const ssoLogin = {
  ...PATHS.ssoLogin,
};

export const messages = {
  ...PATHS.messages,
  thread: interpolate<{ slug: string; code: string }>(PATHS.messages.thread),
};

export const partners = {
  ...PATHS.partners,
};

export const privateTalent = {
  ...PATHS.privateTalent,
  acceptInvitation: interpolate<{ slug: string }>(
    PATHS.privateTalent.acceptInvitation
  ),
  acceptTerms: interpolate<{ slug: string | null }>(
    PATHS.privateTalent.acceptTerms
  ),
};

export const profile = {
  ...PATHS.profile,
  show: interpolate<{ slug: string }>(PATHS.profile.show),
};

export const projects = {
  ...PATHS.projects,
  show: interpolate<{ slug: string }>(PATHS.projects.show),
  public: interpolate<{ slug: string }>(PATHS.projects.public),
};

export const reports = {
  ...PATHS.reports,
};

export const resetPassword = {
  ...PATHS.resetPassword,
};

export const session = {
  ...PATHS.session,
};

export const signUp = {
  ...PATHS.signUp,
};

export const application = {
  ...PATHS.application,
};

export const unsubscribe = {
  ...PATHS.unsubscribe,
};

export const availability = {
  ...PATHS.availability,
};

export const expertSettings = {
  ...PATHS.expertSettings,
};

export const sharedSettings = {
  ...PATHS.sharedSettings,
};

export const verify = {
  ...PATHS.verify,
  expert: interpolate<{ hash: string }>(PATHS.privateTalent.acceptTerms),
};

export const legal = {
  ...PATHS.legal,
  show: interpolate<{ slug: string }>(PATHS.legal.show),
};

export const pay360 = {
  ...PATHS.pay360,
};

export const expertPayments = {
  projectSelection: '/expert/payments/project-selection',
  projectCreation: '/expert/payments/project-creation',
  invoiceUpload: interpolate<{ projectCode: string }>(
    PATHS.expertPayments.invoiceUpload
  ),
  eligibility: '/expert/payments/get-started',
  idVerification: '/expert/payments/identity-verification',
};

export const notifications = {
  ...PATHS.notifications,
};

export const mfa = {
  ...PATHS.mfa,
};
