import React from 'react';
import './Footer.css';
import { openConsentManager } from '@segment/consent-manager';

const Footer = () => {
  return (
    <div className="react-footer">
      <footer role="contentinfo" className="l-footer l-footer--dark">
        <div className="l-flex-container l-container">
          <div className="l-flex-item">
            <small>
              © Copyright {new Date().getFullYear()} by Expert360. All Rights
              Reserved.
            </small>
          </div>
          <nav
            aria-labelledby="block-footersecondary-menu"
            id="block-footersecondary"
            className="l-flex-item"
          >
            <div className="l-flex-item">
              <button
                className="text-button l-footer__nav-item"
                onClick={openConsentManager}
              >
                <span className="l-footer__nav-link">
                  Change Cookie Preferences
                </span>
              </button>
            </div>
            <ul className="menu l-flex-container">
              <li className="l-footer__nav-item">
                <div className="l-footer__nav-item-inner">
                  <a
                    href="https://expert360.com/legal/legal-terms"
                    className="l-footer__nav-link"
                  >
                    Legal
                  </a>
                  <span className="bullet-separator"></span>
                </div>
              </li>
              <li className="l-footer__nav-item">
                <div className="l-footer__nav-item-inner">
                  <a
                    href="https://expert360.com/legal/legal-terms"
                    className="l-footer__nav-link"
                  >
                    Terms
                  </a>
                  <span className="bullet-separator"></span>
                </div>
              </li>
              <li className="l-footer__nav-item">
                <div className="l-footer__nav-item-inner">
                  <a
                    href="https://expert360.com/legal/privacy-policy"
                    className="l-footer__nav-link"
                    data-drupal-link-system-path="node/8"
                  >
                    Privacy
                  </a>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
