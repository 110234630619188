import React from 'react';
import { fetchQuery_DEPRECATED } from 'react-relay';
import { graphql } from 'react-relay';
import { RouteProps } from 'react-router';
import * as E360 from '@expert360';
import equal from 'fast-deep-equal';
import merge from 'deepmerge';

const QUERY = graphql`
  query UserContextHandlerQuery {
    viewer {
      user {
        id
        role
        organisationRole
        verificationLevel
        requiresTotpSetup
        email
        locked
        smsVerified
        emailVerified
        source
        consultant {
          lifecycle {
            accepted
            emailVerified
            basicProfileCompleted
            marketplaceMember
            privateCloudTalent
            claimed
          }
        }
        client {
          memberOf {
            name
            hasExplore
            ssoConfig {
              requireSso
            }
          }
        }
      }
    }
  }
`;

type User = NonNullable<
  NonNullable<E360.UserContextHandlerQuery['viewer']>['user']
>;

type RelayEnvironmentProps = {
  relayEnvironment: any;
};

type UnwrappedProps = {
  user: User | null;
};

type Props = UnwrappedProps & RelayEnvironmentProps & RouteProps;
type State = {
  user: User | null;
};

const UserContext = React.createContext<User | null>(null);

const fetchUser = async (relayEnvironment: any): Promise<E360.Maybe<User>> => {
  const data = await fetchQuery_DEPRECATED<{
    response: E360.UserContextHandlerQuery;
    variables: E360.UserContextHandlerQueryVariables;
  }>(relayEnvironment, QUERY, {}, { force: true });

  return data?.viewer?.user ?? null;
};

class UserContextHandler extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: this.props.user,
    };
  }

  async updateUserContext() {
    const user = await fetchUser(this.props.relayEnvironment);
    const prevUser = this.state.user ?? {};

    if (!user) {
      this.setState({ user: null });
      return;
    }

    if (!equal(prevUser, user)) {
      this.setState(() => ({ user: merge(prevUser, user) }));
    }
  }

  componentDidUpdate(prevProps: Props) {
    // Only update when transitioning between pages
    if (this.props.location?.pathname !== prevProps.location?.pathname) {
      this.updateUserContext();
    }
  }

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export {
  fetchUser,
  Props,
  UnwrappedProps,
  User,
  UserContext,
  UserContextHandler,
};
