import React, { useState, useEffect } from 'react';
import * as UI from '@expert360/ui';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'utils/constants';

import styles from './index.module.css';

type Props = {
  hasUnreadNotifications: boolean;
};

const NotificationIcon: React.FC<Props> = ({ hasUnreadNotifications }) => {
  const [clicked, setClicked] = useState(false);
  const location = useLocation();

  // handle case where /notifications is the first page is accessed
  // as notifications are being read so the icon should have no unread badge
  useEffect(() => {
    if (location.pathname === ROUTES.notifications.index) {
      setClicked(true);
    }
  }, []);

  return (
    <div className={styles.container} onClick={() => setClicked(true)}>
      <div className={styles.iconContainer}>
        <UI.Bell100 size={UI.ESizes.Small} color={'neutralLight'} />
      </div>
      {hasUnreadNotifications && !clicked && <div className={styles.badge} />}
    </div>
  );
};

export default NotificationIcon;
