import React, { useContext } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import * as E360 from '@expert360';
import { ErrorPage } from '../../utils';
import UserContext from '../context/user';
import { User } from '../context/UserContextHandler';
import { CONSULTANT_ROLES } from './ConsultantRoute';
import { ADMIN_ROLES } from './AdminRoute';

export type Props = {
  excludeAdmin?: boolean;
} & RouteProps;

type AllowedRoleRouteProps = {
  user: User;
  role: E360.UserRole;
} & RouteProps;

const NotFoundPage: React.FC = () => <ErrorPage status="404" />;
const NotFoundRoute = ({ path }: RouteProps) => (
  <Route path={path} component={NotFoundPage} />
);

const AllowedRoleRoute = ({
  user,
  role,
  path,
  component,
}: AllowedRoleRouteProps) => {
  if (CONSULTANT_ROLES.includes(role.toLowerCase())) {
    const lifecycle = user?.consultant?.lifecycle;
    const isVettedAndVerified =
      (lifecycle?.accepted ?? false) && (lifecycle?.emailVerified ?? false);

    return isVettedAndVerified ? (
      <Route path={path} component={component} />
    ) : (
      <NotFoundRoute path={path} />
    );
  } else {
    // Admin
    return <Route path={path} component={component} />;
  }
};

const VettedAndVerifiedConsultantRoute: React.FC<Props> = ({
  excludeAdmin = false,
  path,
  component,
}) => {
  const allowedRoles = excludeAdmin
    ? CONSULTANT_ROLES
    : [...CONSULTANT_ROLES, ...ADMIN_ROLES];
  const user = useContext(UserContext);
  const role = user?.role;

  return role && allowedRoles.includes(role.toLowerCase()) ? (
    <AllowedRoleRoute
      role={role}
      user={user as User}
      path={path}
      component={component}
    />
  ) : (
    <NotFoundRoute path={path} />
  );
};

export { VettedAndVerifiedConsultantRoute };
