import React from 'react';
import TransitionGroup from 'react-addons-css-transition-group';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Notification, EColors } from '@expert360/ui';
import { oc } from 'ts-optchain';
import styles from './index.module.css';

export const DEFAULT_TIMEOUT = 3000;

type Icon =
  | 'bell'
  | 'message'
  | 'calendar'
  | 'check'
  | 'clock'
  | 'info'
  | 'declined';

export type Settings = {
  type: 'error' | 'success';
  timeout?: number;
  message: string;
  show?: boolean;
  icon?: Icon | undefined;
} | null;

export type FlashMessageProps = {
  settings: Settings;
  onHide?: any;
};

export type State = {
  message: string | null;
  show: boolean;
  timeout: number;
  icon?: Icon | undefined;
};

type Props = FlashMessageProps &
  RouteComponentProps<Record<string, string | undefined>>;

const DEFAULT_STATE: State = {
  message: null,
  show: false,
  timeout: DEFAULT_TIMEOUT,
  icon: undefined,
};

export class FlashMessageComponent extends React.Component<Props, State> {
  timer: number | null = null;

  constructor(props: Props) {
    super(props);
    const settings = oc(props).settings(null);
    this.state = Object.assign(DEFAULT_STATE, settings);

    if (this.state.show) {
      this.queueHide();
    }
  }

  resetState = () => this.setState(DEFAULT_STATE);

  componentWillUnmount() {
    const { onHide } = this.props;

    if (this.timer) {
      clearTimeout(this.timer);
      onHide && onHide();
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.settings) {
      const newState: State = {
        timeout: nextProps.settings.timeout || DEFAULT_TIMEOUT,
        message: nextProps.settings.message,
        icon: nextProps.settings.icon,
        show: oc(nextProps).settings.show(true),
      };

      this.setState(newState, this.onSetState);
    } else {
      this.resetState();
    }
  }

  onSetState = () => {
    // Timer needs to be cleared no matter
    // what `show` is. The timer will either be
    // re-added or this component renders null
    // making the timeout redundant.
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    if (this.state.show) {
      this.queueHide();
    }
  };

  queueHide = () => {
    const { timeout } = this.state;
    const { onHide } = this.props;

    this.timer = window.setTimeout(() => {
      this.timer = null;
      onHide && onHide();
    }, timeout);
  };

  render() {
    const messageType = oc(this.props).settings.type('error');
    const color = messageType === 'error' ? EColors.Red : EColors.Teal;
    const text = this.state.message || null;
    const icon = this.state.icon || undefined;

    return (
      <TransitionGroup
        className={styles['transition']}
        transitionLeaveTimeout={1000}
        transitionEnterTimeout={1000}
        transitionName="c-feedback"
      >
        {this.state.show && text && (
          <div className={styles['container']}>
            <Notification
              color={color}
              text={text}
              icon={icon}
              flex={false}
              borderRadius={true}
              dropShadow={true}
            />
          </div>
        )}
      </TransitionGroup>
    );
  }
}

export const FlashMessage = withRouter(FlashMessageComponent);
