import React from 'react';
import { RouteProps } from 'react-router-dom';
import { RoleRoute } from './RoleRoute';
import { ADMIN_ROLES } from './AdminRoute';
import { CONSULTANT_ROLES } from './ConsultantRoute';
import { CLIENT_ROLES } from './ClientRoute';

const ALL_ROLES = [...CONSULTANT_ROLES, ...CLIENT_ROLES, ...ADMIN_ROLES];

export const AuthenticatedRoute: React.FC<RouteProps> = props => (
  <RoleRoute roles={ALL_ROLES} {...props} />
);
