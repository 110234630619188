import React from 'react';
import { withRouter } from 'react-router-dom';

const referringCompanyParam = (params: string) => {
  const urlParams = new URLSearchParams(params);
  const referringCompany = urlParams.get('referringCompany');

  return referringCompany ? `&referringCompany=${referringCompany}` : '';
};

export const LoginRedirect: React.FC<any> = ({ location, redirect }) => {
  const returnPath = encodeURIComponent(location.pathname + location.hash);

  /*
    XXX:
    `location.assign` is used here to preserve the referrer after a redirect.
    The referrer is used by the Login component to prevent a user from getting stuck in
    a redirect loop in the edge case that they aren't authorised to view to page
    specified in the `next` param.
  */
  window.location.assign(
    redirect ||
      `/login?next=${returnPath}${referringCompanyParam(location.search)}`
  );

  return null;
};

export const LoginRedirectWithRouter = withRouter(LoginRedirect);
