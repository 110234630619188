import { useCurrentBreakpointName } from 'react-socks';

// "Names" are defaults from Bootstrap 4, as set by React-Socks
export enum Names {
  Xlarge = 'xlarge',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  Xsmall = 'xsmall',
}

const includes = (names: Names[]) =>
  names.includes(useCurrentBreakpointName() as Names);

export const isXsmallDown = () => includes([Names.Xsmall]);
export const isSmallDown = () => includes([Names.Small, Names.Xsmall]);
export const isSmallUp = () =>
  includes([Names.Small, Names.Medium, Names.Large, Names.Xlarge]);

export const isMediumDown = () =>
  includes([Names.Medium, Names.Small, Names.Xsmall]);
export const isMediumUp = () =>
  includes([Names.Medium, Names.Large, Names.Xlarge]);

export const isLargeDown = () =>
  includes([Names.Large, Names.Medium, Names.Small, Names.Xsmall]);
export const isLargeUp = () => includes([Names.Large, Names.Xlarge]);
