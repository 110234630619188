import { formatLocationType } from 'utils';

export default (locationType: string | null, location: string | null = '') => {
  const formattedLocationType = formatLocationType(locationType);
  if (locationType && formattedLocationType) {
    return location
      ? `${location} (${formattedLocationType})`
      : formattedLocationType;
  } else {
    return location ? location : null;
  }
};
