import '../config/polyfills';
import 'react-hot-loader';
import React from 'react';
import './fonts.css';
import { render } from 'react-dom';
import Main from './main';
import '@expert360/ui/lib/expert360-ui.css';
import { createRelayEnvironment, ErrorBoundary } from 'utils';
import getBootstrapDetails from 'core/getBootstrapDetails';
import { fetchUser } from 'core/context/UserContextHandler';
import * as Logger from 'utils/logger';
import { BrowserRouter } from 'react-router-dom';

Logger.init({
  accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.ROLLBAR_ENV,
    client: {
      javascript: {
        code_version: process.env.ROLLBAR_VERSION,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
});

async function initApp() {
  let csrfToken = '',
    flags,
    bootstrapUser;
  let relayEnvironment;
  let contextUser = null;
  let fetchError = null;
  /* 
    Previously if any of the functions threw an error it would be uncaught by the ErrorBoundary
    So if there are any errors it needs to be passed down into the ErrorBoundary 
    and thrown by the child component so the error page can show
  */
  try {
    ({ csrfToken, flags, user: bootstrapUser } = await getBootstrapDetails());
    relayEnvironment = createRelayEnvironment({ csrfToken });
    contextUser = await fetchUser(relayEnvironment);
    const user_id = contextUser?.id && atob(contextUser.id).split(':')[1];
    if (user_id) {
      window.Rollbar.configure({
        payload: {
          person: {
            id: user_id,
            isImpersonating: !!bootstrapUser?.isImpersonating,
          },
        },
      });
    }
  } catch (error) {
    fetchError = error;
  }

  const featureFlags =
    (flags &&
      flags.reduce((flags, flag) => ({ ...flags, [flag]: true }), {})) ??
    {};

  const config = {
    SEGMENT_API_KEY: process.env.SEGMENT_API_KEY,
    csrfToken,
  };

  render(
    <ErrorBoundary>
      <BrowserRouter>
        <Main
          config={config}
          flags={featureFlags}
          relayEnvironment={relayEnvironment}
          bootstrapUser={bootstrapUser}
          contextUser={contextUser}
          error={fetchError}
        />
      </BrowserRouter>
    </ErrorBoundary>,
    document.getElementById('root')
  );
}

initApp();
