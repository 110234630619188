import Rollbar from 'rollbar';

declare global {
  interface Window {
    Rollbar: Rollbar;
  }
}

const init = (config: Rollbar.Configuration) => {
  window.Rollbar = new Rollbar(config);
};

const error = (error: Error, data: Record<string, unknown> = {}) => {
  if (process.env.NODE_ENV !== 'production') {
    console.error(error);
  } else {
    window.Rollbar.error(error.message, error, data);
  }
};

export { error, init };
