import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from '../ErrorPage';
import Loader from 'shared/Loader';

const defaultErrorHandler = () => <ErrorPage status="500" />;

const RelayRenderContainer = (
  Component,
  errorHandler = defaultErrorHandler,
  propName = null
) => {
  const RelayWrapper = ({ error, props }) => {
    if (props) {
      return <Component graph={propName ? props[propName] : props} />;
    } else if (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      const errors = error.source && error.source.errors;

      return errorHandler(errors) || defaultErrorHandler();
    } else {
      return <Loader />;
    }
  };

  RelayWrapper.propTypes = {
    error: PropTypes.object,
    props: PropTypes.object,
  };

  RelayWrapper.displayName = `RelayRenderContainer(${Component.displayName ||
    Component.name ||
    'Component'})`;

  return RelayWrapper;
};

export default RelayRenderContainer;
