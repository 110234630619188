import React from 'react';
import { QueryRenderer } from 'react-relay';
import Loader from 'shared/Loader';
import ErrorPage from '../ErrorPage';

const QueryRendererContainer = ({
  render,
  renderLoading = () => <Loader />,
  renderError = () => <ErrorPage status="500" />,
  ...queryRenderProps
}) => (
  <QueryRenderer
    dataFrom="STORE_THEN_NETWORK"
    {...queryRenderProps}
    render={({ error, props, retry }) => {
      if (props) {
        return render(props, retry);
      } else if (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return renderError(error, retry);
      } else {
        return renderLoading();
      }
    }}
  />
);

export default QueryRendererContainer;
