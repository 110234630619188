const qs = require('query-string');

/**
 * The query-string library purposely returns Objects with no prototypes,
 * which causes React to crash when attempting to do a comparison on those values.
 * See https://expert360.atlassian.net/browse/FIX-753
 */
const parseObject = (input: string) => Object.assign({}, qs.parse(input));

const ensureArray = (input: any) =>
  (Array.isArray(input) ? input : [input]).filter(v => !!v);

const get = (parsedObject: Record<string, any>, key: string) =>
  parsedObject ? ensureArray(parsedObject[key]) : [];

const parse = qs.parse;

export default {
  get,
  ensureArray,
  parse,
  parseObject,
};
