const React = require('react');
const PropTypes = require('prop-types');

export default (Component: React.FC) => {
  const ConfigWrapper = (
    props: any,
    context: { config: Record<string, unknown> }
  ) => {
    return React.createElement(Component, {
      ...props,
      config: context.config,
    });
  };

  ConfigWrapper.contextTypes = {
    config: PropTypes.object,
  };
  ConfigWrapper.displayName = `withConfig(${Component.displayName ||
    Component.name})`;

  return ConfigWrapper;
};
