// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationBanner-module__notificationRow___hoQJ0 {\n  padding: 8px !important;\n}\n\n@media all and (min-width: 576px) {\n  .NotificationBanner-module__notificationRow___hoQJ0 {\n    padding: 8px 0 !important;\n  }\n}", "",{"version":3,"sources":["webpack://./src/layout/NotificationBanner.module.css"],"names":[],"mappings":"AAAA;EACE,uBAA4B;AAC9B;;AAEA;EACE;IACE,yBAA8B;EAChC;AACF","sourcesContent":[".notificationRow {\n  padding: units(2) !important;\n}\n\n@media all and (min-width: breakpoint(medium)) {\n  .notificationRow {\n    padding: units(2) 0 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationRow": "NotificationBanner-module__notificationRow___hoQJ0"
};
module.exports = ___CSS_LOADER_EXPORT___;
