import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { AnyRecord } from 'dns';

function formatAsYear(dateAsIsoString: string) {
  return parse(dateAsIsoString).getFullYear();
}

function checkType(arg: any) {
  return typeof arg;
}

function isText(text: AnyRecord) {
  return checkType(text) === 'string';
}

export function formatProjectDate(dateStart: string, dateCompleted: string) {
  if (!dateStart && !dateCompleted) {
    return '';
  }

  const startYear = dateStart && formatAsYear(dateStart);
  const endYear = dateCompleted && formatAsYear(dateCompleted);

  if (startYear === endYear) {
    return startYear;
  }
  return [startYear, endYear].filter(x => !!x).join(' - ');
}

export function formatDateWithYear(dateTimeStamp: string | Date | number) {
  if (!dateTimeStamp || !parse(dateTimeStamp)) {
    return '';
  }

  return format(parse(dateTimeStamp), 'D MMM YYYY');
}

export function formatDateWithoutYear(
  dateTimeStamp: string | null | undefined
) {
  if (!dateTimeStamp || !parse(dateTimeStamp)) {
    return '';
  }

  return format(parse(dateTimeStamp), 'D MMM');
}

export function formatTextUpperCase(text: any) {
  return isText(text) ? text.toUpperCase() : '';
}

export function formatCapitalFirstLetter(text: any) {
  return isText(text) ? text.charAt(0).toUpperCase() + text.slice(1) : '';
}

export function formatCapitalize(text: string) {
  return text.toLowerCase().replace(/\b\w/g, l => l.toUpperCase());
}

export const formatNumberToDollarAmount = (float: any) =>
  Number(float).toLocaleString();

export const formatBackOfficeDate = (isoDateStr: string | null) => {
  if (isoDateStr === null) {
    return 'None';
  } else {
    return format(parse(isoDateStr), 'D MMM YYYY, [a][t] h:mm:ssa');
  }
};

export const pluralise = (
  word: string,
  number: number | undefined | null | string
) => (number === 1 ? word : `${word}s`);

export const formatIdealStart = (
  startAsap: boolean,
  dateStart: string | null
) => {
  // New selections on the create project form:
  // 1 - 2 weeks -> sets startAsap true
  // flexible -> sets startAsap false + dateSart null
  // select date -> startAsap false + dateStart as date selected
  if (startAsap) {
    return '1 to 2 weeks';
  }
  if (!startAsap && !dateStart) {
    return 'Flexible';
  }
  if (!startAsap && dateStart) {
    return formatDateWithYear(dateStart);
  }
};

// organisationIndustry is the organisation's industry
// projectBriefIndustries is the client selected industries from the project brief.
// this handles data coming back from ES as an array of strings: ['x', 'y']
// and handles data coming from GQL coming as array of objects [{name: 'x'}, {name: 'y'}]
export const formatIndustry = (
  organisationIndustry: any,
  projectBriefIndustries: any
) => {
  const isEveryIndustryAnObject = (industry: any) =>
    typeof industry === 'object';

  let result = null;
  if (projectBriefIndustries && projectBriefIndustries.length > 0) {
    result = (projectBriefIndustries.every(isEveryIndustryAnObject)
      ? projectBriefIndustries.map((industry: any) => industry.name)
      : projectBriefIndustries
    ).join(', ');
  } else if (organisationIndustry) {
    result = organisationIndustry;
  }
  return result;
};

export const genValidUrl = (url: string | null | undefined) => {
  const protocolRegex = /^(http(s?)):\/\//gi;
  return url && url.match(protocolRegex) ? url : `http://${url}`;
};

// checks that the path in next param isn't trying to redirect to another origin
export const formatNextParam = (next: string | null | undefined) => {
  if (
    next &&
    new URL(document.baseURI).origin !== new URL(next, document.baseURI).origin
  ) {
    return '/';
  } else {
    return next;
  }
};

export function formatLocationType(locationType: string | null) {
  if (locationType === 'onsite') {
    return 'On-site';
  } else if (locationType === 'remote') {
    return 'Remote';
  } else if (locationType === 'hybrid') {
    return 'Hybrid';
  } else {
    return null;
  }
}
