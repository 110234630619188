import React from 'react';
import { hot } from 'react-hot-loader/root';

class HotLoader extends React.Component {
  render() {
    return this.props.children;
  }
}

export default hot(HotLoader);
