import React from 'react';
import PropTypes from 'prop-types';

type ConfigProviderProps = {
  config: Record<string, unknown>;
  children: React.ReactNode;
};

class ConfigProvider extends React.Component<ConfigProviderProps> {
  static childContextTypes = {
    config: PropTypes.object.isRequired,
  };

  getChildContext() {
    return {
      config: this.props.config,
    };
  }

  render() {
    return this.props.children;
  }
}

export default ConfigProvider;
