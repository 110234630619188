export type Params = { [key: string]: string | number };
export type Interpolate = <T = Params>(path: string) => (params: T) => string;

const prefixColon = (k: string) => `:${k}`;

const makeRegExp = (obj: any) =>
  new RegExp(
    Object.keys(obj)
      .map(prefixColon)
      .join('|'),
    'gi'
  );

// Matches anything in parens for removal
// e.g., /:tab(archive|current)/view -> /archive/view
const predefinedAndOptionals = new RegExp(/\(([^)]+)\)/, 'gi');

export const interpolate: Interpolate = path => params =>
  path
    .replace(
      makeRegExp(params),
      // FIXME: Element implicitly has an 'any' type because expression of
      // type 'string' can't be used to index type 'unknown'.
      (key: string) => (<any>params)[key.replace(':', '').toLowerCase()]
    )
    .replace(predefinedAndOptionals, '');
