import React, { useState } from 'react';
import { graphql } from 'react-relay';
import { QueryRendererContainer } from 'utils';
import withRelayEnvironment from 'core/withRelayEnvironment';
import Expert from './Expert';
import ExpertFallback from './ExpertFallback';

const ExpertRendererQuery = graphql`
  query ExpertRendererQuery {
    viewer {
      hasUnreadNotifications
      displayBrowseProjects
      user {
        consultant {
          lifecycle {
            basicProfileCompleted
          }
        }
        profilePicture(size: LARGE) {
          source
        }
      }
    }
  }
`;

const ExpertRenderer = ({
  csrfToken,
  isProfileComplete,
  userFirstName,
  userFullName,
  userProfileCode,
  userProfileImg,
  featureFlagger,
  relayEnvironment,
  tracking,
  userVerificationLevel,
  isMarketplaceMember,
  referringCompany,
}) => {
  const [isMenuOpen, toggleMenu] = useState(false);
  const isVerified =
    userVerificationLevel > 0 ||
    userVerificationLevel === 'VETTED' ||
    userVerificationLevel === 'FUTURED';

  const ExpertFallbackComponent = () => (
    <ExpertFallback
      userFirstName={userFirstName}
      userFullName={userFullName}
      isProfileComplete={isProfileComplete}
      userProfileCode={userProfileCode}
      userProfileImg={userProfileImg}
      csrfToken={csrfToken}
      isMenuOpen={isMenuOpen}
      toggleMenu={toggleMenu}
      isVerified={isVerified}
      isMarketplaceMember={isMarketplaceMember}
      referringCompany={referringCompany}
    />
  );
  return (
    <QueryRendererContainer
      environment={relayEnvironment}
      query={ExpertRendererQuery}
      renderLoading={ExpertFallbackComponent}
      renderError={ExpertFallbackComponent}
      render={data => (
        <Expert
          data={data}
          userFirstName={userFirstName}
          userFullName={userFullName}
          userProfileCode={userProfileCode}
          csrfToken={csrfToken}
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          isVerified={isVerified}
          isMarketplaceMember={isMarketplaceMember}
          referringCompany={referringCompany}
        />
      )}
    />
  );
};

export default withRelayEnvironment(ExpertRenderer);
