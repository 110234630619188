import {
  Environment,
  Network,
  RecordSource,
  Store,
  QueryResponseCache,
} from 'relay-runtime';

interface RelayEnvironmentOptions {
  csrfToken?: string;
  recordSource?: RecordSource;
  targetUri?: string;
}

interface QueryResponse {
  errors?: any[];
  data?: any;
}

function createRelayEnvironment(
  opts: RelayEnvironmentOptions = {}
): Environment {
  const {
    csrfToken,
    recordSource = new RecordSource(),
    targetUri = '/graphql',
  } = opts;

  const store = new Store(recordSource);

  let headers: Record<string, string> = {
    Accept: 'application/json, application/expert360.graphql',
    'Content-Type': 'application/json',
  };

  if (csrfToken) {
    headers = { ...headers, 'x-csrf-token': csrfToken };
  }

  const cache = new QueryResponseCache({ size: 100, ttl: 100000 });

  const network = Network.create((operation, variables, cacheConfig) => {
    const queryId = operation.name;
    const cachedData = cache.get(queryId, variables);

    // Handle force option in RefetchOptions
    // See: https://facebook.github.io/relay/docs/pagination-container.html
    // https://facebook.github.io/relay/docs/refetch-container.html
    const forceLoad = cacheConfig && cacheConfig.force;
    if (!forceLoad && cachedData && operation.name !== 'userQuery') {
      return cachedData;
    }

    if (forceLoad) {
      // clear() means to reset all the cache, not only the entry addressed by specific queryId.
      cache.clear();
    }

    return fetch(targetUri, {
      method: 'POST',
      headers,
      credentials: 'same-origin',
      body: JSON.stringify({
        query: operation.text,
        operationName: operation.name,
        variables,
      }),
    })
      .then(response => {
        if (response.status === 403) {
          return { errors: [{ message: 'Network error' }] };
        }
        return response.json();
      })
      .then(({ errors, data }: QueryResponse) => {
        if (errors) {
          return { errors, data };
        }

        cache.set(queryId, variables, { data });
        return { data };
      });
  });

  return new Environment({
    network,
    store,
  });
}

export { createRelayEnvironment };
