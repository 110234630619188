import React from 'react';
import cx from 'classnames';
import { ROUTES } from 'utils/constants';
import * as Utils from 'utils';
import * as Request from 'utils/request';

const logout = (csrfToken: string) => {
  return Request.delete(ROUTES.session.index, csrfToken);
};

type Config = {
  csrfToken: string;
};

type Props = {
  config: Config;
  isClient?: boolean;
};

const Logout: React.FC<Props> = ({ config, isClient = false }) => {
  const { csrfToken } = config;

  return (
    <a
      className={cx('l-header__sub-nav-item', {
        'is-client': isClient,
      })}
      onClick={async evt => {
        evt.preventDefault();

        try {
          await logout(csrfToken);
        } catch (error) {
          // A 403 error is expected if the user has already logged out in another
          // tab or their session has expired. Only throw the error (for Rollbar to pick up)
          // if it isn't a 403.
          if (!Request.is403Error(error)) {
            throw error;
          }
        } finally {
          // Whether or not there's an error, go back to /. In the case of an error, this will
          // reset state.
          window.location.assign('/');
        }
      }}
      data-test="mm-logout"
      tabIndex={0}
      href=""
    >
      Logout
    </a>
  );
};

export { Logout, Props };
export default Utils.compose(Utils.withConfig)(Logout);
