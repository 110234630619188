import FeatureFlagProvider from '../utils/FeatureFlagProvider';
import { useContext } from 'react';

function useFeatureFlags() {
  const flags = useContext(FeatureFlagProvider);
  return flags;
}

function useFeatureFlag(flag: string): boolean {
  return useFeatureFlags().enabled(flag);
}

export { useFeatureFlag, useFeatureFlags };
export default useFeatureFlags;
