import React from 'react';
import EnvVariableProvider from '../EnvVariableProvider/EnvVariableProvider';
import EnvVariableContainer from '../EnvVariableContainer/EnvVariableContainer';

export default function withEnvVariable(Component: React.ComponentType<any>) {
  const EnvVariableWrapper = (props: any) => {
    return (
      <EnvVariableProvider.Consumer>
        {(container: EnvVariableContainer) => (
          <Component {...props} envVariable={container} />
        )}
      </EnvVariableProvider.Consumer>
    );
  };

  EnvVariableWrapper.displayName = `withEnvVariable(${Component.displayName ||
    Component.name ||
    'Component'})`;

  return EnvVariableWrapper;
}
