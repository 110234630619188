import differenceInSeconds from 'date-fns/difference_in_seconds';
import { padStart } from 'lodash';

export function isOlderThanSevenDays(pastDate: Date | null) {
  const sevenDaysInSeconds = 604800;
  const difference = pastDate
    ? differenceInSeconds(Date.now(), new Date(pastDate))
    : null;
  return difference ? difference > sevenDaysInSeconds : true;
}

export function localTimeZoneOffset(
  javascriptOffsetInMinutes = new Date().getTimezoneOffset()
) {
  const totalOffsetMinutes = javascriptOffsetInMinutes * -1;
  const offsetHours = Math.floor(Math.abs(totalOffsetMinutes / 60));
  const offsetMinutes = Math.abs(totalOffsetMinutes % 60);
  const sign = totalOffsetMinutes < 0 ? '-' : '+';
  const hh = padStart(offsetHours.toString(), 2, '0');
  const mm = padStart(offsetMinutes.toString(), 2, '0');
  return `${sign}${hh}:${mm}`;
}
