import React from 'react';
import withRelayEnvironment from 'core/withRelayEnvironment';
import { compose, QueryRendererContainer } from 'utils';
import { graphql } from 'react-relay';
import { Header } from '../../Header';
import * as E360 from '@expert360';

type Props = {
  relayEnvironment: any;
  user: any;
  name: string;
};

const query = graphql`
  query ReferringCompanyQuery($displayName: String) {
    contractingCompany(displayName: $displayName) {
      lightLogoUrl
    }
  }
`;

const ReferringCompany: React.FC<Props> = ({
  relayEnvironment,
  user,
  name,
}) => {
  return (
    <QueryRendererContainer
      environment={relayEnvironment}
      query={query}
      variables={{ displayName: name }}
      render={(graph: E360.ReferringCompanyQuery) => {
        const logoUrl = graph?.contractingCompany?.lightLogoUrl ?? '';
        return (
          <Header
            user={user}
            referringCompanyImageUrl={logoUrl}
            hideCta={true}
          />
        );
      }}
    />
  );
};

export default compose(withRelayEnvironment)(ReferringCompany);
