import React from 'react';

const showIntercomMessage = (e: React.MouseEvent<HTMLElement>) => {
  if ('Intercom' in window) {
    e.preventDefault();
    (window as any).Intercom('showNewMessage');
  }
};

export default function GetInTouchButton() {
  if (typeof window === 'undefined') {
    return (
      <a
        className="c-button-faux c-button-faux--inline c-button-faux--size-base"
        href="mailto:info@expert360.com"
      >
        get in touch
      </a>
    );
  }

  return (
    <button
      className="c-button-faux c-button-faux--inline c-button-faux--size-base"
      type="button"
      onClick={showIntercomMessage}
    >
      get in touch
    </button>
  );
}
