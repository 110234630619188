import useFeatureFlags from 'hooks/useFeatureFlags';

interface Render {
  flag: string;
  render: React.ReactNode;
  children?: React.ReactNode;
}

interface Children {
  flag: string;
  render?: React.ReactNode;
  children: React.ReactNode;
}

export type Props = Render | Children;

const FeatureFlag: React.FC<Props> = ({ flag, render, children }) => {
  const featureFlagContainer = useFeatureFlags();
  const enabled = featureFlagContainer.enabled(flag);
  if (render && typeof render === 'function') {
    return render({ enabled });
  }

  if (enabled) {
    return children;
  }

  return null;
};

export default FeatureFlag;
