enum RequestMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Post = 'POST',
}

class ResponseError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.name = 'ResponseError';
    this.response = response;
  }
}

const isResponseError = (error: Error): error is ResponseError => {
  return error instanceof ResponseError;
};

const isStatusError = (error: Error, status: number): boolean => {
  return isResponseError(error) && error.response.status === status;
};

const is401Error = (error: Error) => {
  return isStatusError(error, 401);
};

const is403Error = (error: Error) => {
  return isStatusError(error, 403);
};

const is404Error = (error: Error) => {
  return isStatusError(error, 404);
};

const statusCheck = (response: Response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new ResponseError(
      `API error: returned ${response.status} status code`,
      response
    );
    throw error;
  }
};

const json = (response: Response) => {
  const contentType = response.headers.get('Content-Type');
  if (contentType && contentType.includes('application/json')) {
    return response.json();
  }

  return response;
};

type RequestOpts = {
  headers?: { [key: string]: string };
  body?: Record<string, unknown>;
  method?: RequestMethod;
};

const request = (path: string, csrfToken: string, opts: RequestOpts = {}) => {
  if (!csrfToken) {
    throw new Error('API error: no CSRF token provided for API request');
  }

  const { method = RequestMethod.Get, body, headers, ...otherOpts } = opts;

  const requestOpts: RequestInit = {
    method,
    credentials: 'same-origin',
    headers: new Headers({
      ...headers,
      'x-csrf-token': csrfToken,
    }),
    ...otherOpts,
  };

  if (body) {
    requestOpts.body = JSON.stringify(body);
    (requestOpts.headers as Headers).set('Content-Type', 'application/json');
  }

  return fetch(path, requestOpts)
    .then(statusCheck)
    .then(json);
};

const del = (path: string, csrfToken: string, opts: RequestOpts = {}) => {
  return request(path, csrfToken, { ...opts, method: RequestMethod.Delete });
};

const post = (path: string, csrfToken: string, opts: RequestOpts = {}) => {
  return request(path, csrfToken, { ...opts, method: RequestMethod.Post });
};

export {
  del as delete,
  is401Error,
  is403Error,
  is404Error,
  isResponseError,
  isStatusError,
  post,
  request,
  RequestMethod,
  ResponseError,
  statusCheck,
};
