import React from 'react';
import FeatureFlagProvider from '../FeatureFlagProvider/FeatureFlagProvider';
import FeatureFlagContainer from '../FeatureFlagContainer/FeatureFlagContainer';

export default function withFeatureFlagger(
  Component: React.ComponentType<any>
) {
  const FeatureFlagWrapper = (props: any) => {
    return (
      <FeatureFlagProvider.Consumer>
        {(container: FeatureFlagContainer) => (
          <Component {...props} featureFlagger={container} />
        )}
      </FeatureFlagProvider.Consumer>
    );
  };

  FeatureFlagWrapper.displayName = `withFeatureFlagger(${Component.displayName ||
    Component.name ||
    'Component'})`;

  return FeatureFlagWrapper;
}
