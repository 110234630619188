import React from 'react';
import withRelayEnvironment from 'core/withRelayEnvironment';
import { compose, QueryRendererContainer } from 'utils';
import { graphql } from 'react-relay';
import { Header } from '../../Header';
import * as E360 from '@expert360';

type Props = {
  relayEnvironment: any;
  user: any;
  name: string;
};

const query = graphql`
  query InvitingOrganisationQuery($name: String) {
    organisations(first: 1, name: $name) {
      edges {
        node {
          id
          name
          lightWhitelabelImage
        }
      }
    }
  }
`;

const InvitingOrganisation: React.FC<Props> = ({
  relayEnvironment,
  user,
  name,
}) => {
  return (
    <QueryRendererContainer
      environment={relayEnvironment}
      query={query}
      variables={{ name: name }}
      render={(graph: E360.InvitingOrganisationQuery) => {
        const orgEdges = graph?.organisations?.edges;
        const whitelabelImage = orgEdges
          ? orgEdges[0]?.node?.lightWhitelabelImage ?? ''
          : '';
        return (
          <Header
            user={user}
            whitelabelImageUrl={whitelabelImage}
            hideCta={true}
          />
        );
      }}
    />
  );
};

export default compose(withRelayEnvironment)(InvitingOrganisation);
