// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-module__container___SfFfV {\n  position: relative;\n  height: 28px;\n  width: 28px;\n}\n\n.index-module__iconContainer___QrqS4 {\n  height: 28px;\n  width: 28px;\n  background-color: #014F59;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  border-radius: 100%;\n  position: absolute;\n}\n\n.index-module__iconContainer___QrqS4:hover {\n  background-color:#007282;\n}\n\n.index-module__badge___Rl24r {\n  background-color: red;\n  height: 8px;\n  width: 8px;\n  border: 1px solid white !important;\n  border-radius: 100%;\n  position: relative;\n  float: right;\n}", "",{"version":3,"sources":["webpack://./src/layout/components/NotificationIcon/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,UAAU;EACV,kCAAkC;EAClC,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".container {\n  position: relative;\n  height: 28px;\n  width: 28px;\n}\n\n.iconContainer {\n  height: 28px;\n  width: 28px;\n  background-color: #014F59;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  border-radius: 100%;\n  position: absolute;\n}\n\n.iconContainer:hover {\n  background-color:#007282;\n}\n\n.badge {\n  background-color: red;\n  height: 8px;\n  width: 8px;\n  border: 1px solid white !important;\n  border-radius: 100%;\n  position: relative;\n  float: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "index-module__container___SfFfV",
	"iconContainer": "index-module__iconContainer___QrqS4",
	"badge": "index-module__badge___Rl24r"
};
module.exports = ___CSS_LOADER_EXPORT___;
