import React from 'react';
import { Helmet } from 'react-helmet';

interface PageTitleProps {
  title?: string | null;
}

export default function PageTitle({ title }: PageTitleProps) {
  const fullTitle = title ? `${title} - ` : '';

  return (
    <Helmet>
      <title>{fullTitle}Expert360</title>
    </Helmet>
  );
}
