import React from 'react';
import RelayEnvironmentContext from './context/relayEnvironment';

const withRelayEnvironment = Component => {
  const RelayEnvWrapper = props => (
    <RelayEnvironmentContext.Consumer>
      {({ relayEnvironment }) => (
        <Component {...props} relayEnvironment={relayEnvironment} />
      )}
    </RelayEnvironmentContext.Consumer>
  );

  const displayName = Component.displayName || Component.name || 'Component';
  RelayEnvWrapper.displayName = `withRelayEnvironment(${displayName})`;

  return RelayEnvWrapper;
};

export { Environment as RelayEnvironmentType } from 'react-relay';

export default withRelayEnvironment;
