import React from 'react';
import Loader from 'shared/Loader';
import styles from './index.module.css';

const Loading = () => (
  <div className={styles.container}>
    <div className={styles.innerContainer}>
      <Loader />
    </div>
  </div>
);

export default Loading;
