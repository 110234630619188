import React from 'react';
import { Route, RouteProps, matchPath } from 'react-router-dom';
import * as E360 from '@expert360';
import { NotFoundRoute } from '../NotFoundRoute';
import { expert as ExpertPaths } from 'utils/constants/routes/paths';

export type Expert = {
  consultant: E360.Maybe<{
    lifecycle: E360.Maybe<{
      accepted?: boolean | null | undefined;
      emailVerified?: boolean | null | undefined;
      privateCloudTalent?: boolean | null | undefined;
      basicProfileCompleted?: boolean | null | undefined;
      claimed?: boolean | null | undefined;
    }>;
  }>;
  source?: string;
};

export type Props = {
  expert: E360.Maybe<Expert>;
} & RouteProps;

const isAcceptedAndVerified = (expert: Expert) => {
  return (
    (expert.consultant?.lifecycle?.accepted ?? false) &&
    (expert.consultant?.lifecycle?.emailVerified ?? false)
  );
};

const isPrivateCloudMember = (expert: Expert) =>
  expert.consultant?.lifecycle?.privateCloudTalent ?? false;

const isBasicProfileCompleted = (expert: Expert) =>
  expert.consultant?.lifecycle?.basicProfileCompleted ?? false;

/* 
  this is to bypass profile completion step for those who were invited and made an offer
  if more pages are need to be allowed then we can rename the function and tweak the code to handle a list of paths
*/
const isDirectInviteAndProjectDetails = (
  expert: Expert,
  locationPathname: string | undefined
) =>
  expert.consultant?.lifecycle?.claimed &&
  expert.source === 'direct-invite' &&
  locationPathname &&
  matchPath(locationPathname, {
    path: ExpertPaths.projectDetails,
  }) != null;

const isAllowed = (expert: Expert, locationPathname: string | undefined) =>
  ((isAcceptedAndVerified(expert) || isPrivateCloudMember(expert)) &&
    isBasicProfileCompleted(expert)) ||
  isDirectInviteAndProjectDetails(expert, locationPathname);

export const ExpertRoute: React.FC<Props> = ({ expert, ...props }) => {
  if (expert && isAllowed(expert, props.location?.pathname)) {
    return <Route {...props} />;
  }

  return <NotFoundRoute {...props} />;
};
