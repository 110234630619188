// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-module__transition___pEQao {\n  display: block;\n  position: relative;\n  width: 100%;\n}\n\n.index-module__container___WAH0G {\n  width: 100%;\n  z-index: 7;\n  display: block;\n  position: fixed;\n  z-index: 7;\n  width: 100%;\n  margin-top: 18px;\n}", "",{"version":3,"sources":["webpack://./src/shared/FlashMessage/index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,UAAU;EACV,cAAc;EACd,eAAe;EACf,UAAU;EACV,WAAW;EACX,gBAAsB;AACxB","sourcesContent":[".transition {\n  display: block;\n  position: relative;\n  width: 100%;\n}\n\n.container {\n  width: 100%;\n  z-index: 7;\n  display: block;\n  position: fixed;\n  z-index: 7;\n  width: 100%;\n  margin-top: units(4.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transition": "index-module__transition___pEQao",
	"container": "index-module__container___WAH0G"
};
module.exports = ___CSS_LOADER_EXPORT___;
