export const project = {
  expertAnsweredQuestions: 'project-expert-answered-questions',
  expertBeingInterviewed: 'project-expert-being-interviewed',
  contractAgreed: 'project-contract-agreed',
  contractOffered: 'project-contract-offered',
  contractCounteroffer: 'project-contract-counteroffer',
  contractConsultantOffered: 'project-contract-consultant-offered',
  expertInvited: 'project-expert-invited',
  expertHired: 'project-expert-hired',
  expertDeclineInvitation: 'project-expert-decline-invitation',
  message: 'project-message',
  answerDeclined: 'project-answer-declined',
  answerAccepted: 'project-answer-accepted',
  expertDeclined: 'project-expert-declined',
  closed: 'project-closed',
  messageClose: 'project-message-close',
  contractModified: 'project-contract-modified',
  cancelled: 'project-cancelled',
  created: 'project-created',
  published: 'project-published',
  activated: 'project-activated',
  onhold: 'project-onhold',
};
