import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface PhoneInputParams {
  value: string;
  onChange: any;
}

const MobilePhoneInput = ({ value, onChange }: PhoneInputParams) => {
  return (
    <PhoneInput
      value={value}
      onChange={onChange}
      placeholder="(04) 1234 5678"
      country="au"
      onlyCountries={['au']}
      disableCountryCode
    />
  );
};

export default MobilePhoneInput;
